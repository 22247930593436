export default class templateProduct { 
  constructor() { 
    this.fees = [
        {
          party_type: 1,
          party_type_name:'INSURER',
          party_id:null,
          party_new_commission: 0,
          party_renew_commission: 0,
          party_new_fees: 0,
          party_renew_fees: 0,
          status:false,
          party_end_fees: 0
        },

        {
          party_type: 2,
          party_type_name:'BROKER',
          party_id:null,
          party_new_commission: 0,
          party_renew_commission: 0,
          party_new_fees: 0,
          party_renew_fees: 0,
          status:false,
          party_end_fees: 0
        },
        {
          party_type:3,
          party_type_name:'REFERER',
          party_id:null,
          party_new_commission: 0,
          party_renew_commission: 0,
          party_new_fees: 0,
          party_renew_fees: 0,
          status:false,
          party_end_fees: 0
        },
      ],
    this.info = {
      excess:0,
    }
  }
}