<template>
  <div v-if="item">
    <v-form ref="form" v-model="valid" lazy-validation>
      <v-container fluid>
        <v-row no-gutters>
          <v-col>
            <v-text-field
              v-model="item.name"
              label="Template Name"
              :rules="name_rules"
              required
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row no-gutters>
          <v-col>
            <v-switch v-model="item.status" inset label="Status"></v-switch>
          </v-col>
        </v-row>
        <v-row no-gutters>
          <v-col>
            <v-autocomplete
              v-model="item.franchise_id"
              :rules="required_rules"
              :loading="franchise_loading"
              @focus="getAllFranchises()"
              :items="franchise_items"
              :search-input.sync="franchise_search"
              item-text="name"
              item-value="id"
              flat
              label="Franchise"
              autocomplete="nope"
              @input="setBusinessType(item.franchise_id)"
            ></v-autocomplete>
            <!-- cache-items -->
          </v-col>
        </v-row>
        <v-row no-gutters>
          <v-col>
            <v-autocomplete
              v-model="item.cluster_group_sub_lookup_id"
              :loading="clusterLoading"
              :items="clusterItems"
              item-text="name"
              item-value="id"
              flat
              label="Cluster Group"
              autocomplete="nope"
              :disabled="clusterItems.length < 1"
            ></v-autocomplete>
            <!-- cache-items -->
          </v-col>
        </v-row>
        <v-row no-gutters>
          <v-col>
            <v-text-field
              v-model="item.biz_type"
              label="Business Type"
              required
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row no-gutters>
          <v-col> </v-col>
        </v-row>
        <v-row no-gutters>
          <v-col cols="12" md="4">
            <!-- :close-on-content-click="false" -->
            <v-menu
              v-model="menu_start_dt"
              :nudge-right="40"
              transition="scale-transition"
              offset-y
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="item.start_dt"
                  :rules="required_rules"
                  label="Program Start Date"
                  prepend-icon="mdi-calendar"
                  readonly
                  v-bind="attrs"
                  v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="item.start_dt"
                @input="menu_start_dt = false"
              ></v-date-picker>
            </v-menu>
          </v-col>
          <v-col cols="12" md="4" offset-md="2">
            <v-menu
              v-model="menu_end_dt"
              :nudge-right="40"
              transition="scale-transition"
              offset-y
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="item.end_dt"
                  :rules="required_rules"
                  label="Program End Date"
                  prepend-icon="mdi-calendar"
                  readonly
                  v-bind="attrs"
                  v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="item.end_dt"
                @input="menu_end_dt = false"
              ></v-date-picker>
            </v-menu>
          </v-col>
        </v-row>

        <v-row><v-col></v-col></v-row>

        <v-row no-gutters>
          <v-col cols="12">
            Expiry Logic
          </v-col>
          <v-col cols="12" sm="8" md="6">
            <v-radio-group
              v-model="item.expiry_logic"
              :rules="required_rules"
              row
            >
              <v-radio label="Push Forward" :value="1"></v-radio>
              <v-radio label="Block" :value="2"></v-radio>
            </v-radio-group>
          </v-col>
          <v-col cols="12" sm="4" md="6">
            <v-text-field
              v-model="item.expiry_logic_min_len"
              :rules="required_rules"
              label="Minimum Length"
              required
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row no-gutters>
          <v-col cols="12">
            Expiry Type
          </v-col>
          <v-col cols="12" sm="8" md="6">
            <v-radio-group
              v-model="item.expiry_type"
              :rules="required_rules"
              row
            >
              <v-radio label="Common" :value="1"></v-radio>
              <v-radio label="Variable" :value="2"></v-radio>
            </v-radio-group>
          </v-col>
          <v-col cols="12" sm="4" md="6">
            <v-text-field
              v-model="item.notice_days"
              label="Admin Notice days"
              required
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row no-gutters>
          <v-col>
            <v-autocomplete
              @focus="getAllSuspendedTemplated()"
              v-model="item.superseded_template_id"
              :loading="template_loading"
              :items="superseded_template_items"
              :search-input.sync="template_search"
              item-text="name"
              item-value="id"
              autocomplete="nope"
              flat
              label="Superseded Template"
            ></v-autocomplete>
          </v-col>
        </v-row>
        <v-row no-gutters>
          <v-col>
            <v-switch
              v-model="item.force_policy_num_change_on_renewal"
              inset
              label="Force Policy Number Change On Renewal"
            ></v-switch>
          </v-col>
        </v-row>
        <v-row no-gutters>
          <v-col>
            <v-text-field
              v-model="item.referral_emails"
              label="Send referrals to"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row no-gutters>
          <v-col cols="12">
            Send email to
          </v-col>
          <v-col md="4">
            <v-switch
              v-model="item.email_insurer"
              inset
              label="Insurer"
            ></v-switch>
          </v-col>
          <v-col md="4">
            <v-switch
              v-model="item.email_broker"
              inset
              label="Broker"
            ></v-switch>
          </v-col>
          <v-col md="4">
            <v-switch v-model="item.email_agent" inset label="Agent"></v-switch>
          </v-col>
        </v-row>
        <v-row no-gutters>
          <v-col>
            <v-text-field
              v-model="item.formal_policy_num"
              label="Formal Policy Number"
            ></v-text-field>
          </v-col>
        </v-row>

        <v-row>
          <v-btn
            :loading="loading"
            :disabled="loading"
            color="success"
            @click="validate"
          >
            <v-icon small left>mdi-check</v-icon>
            Save
          </v-btn>
          <v-btn class="mx-3" outlined @click="reset">
            Reset Form
          </v-btn>
        </v-row>
      </v-container>
    </v-form>
  </div>
</template>

<script>
import { debounce } from "@/helpers/basic_helper";
import { crud_state } from "@/lib/constants";
import templateService from "@/services/template.service.js";
import franchiseService from "@/services/franchise.service.js";
import BaseService from "@/services/base.service.js";

export default {
  name: "template-basic-edit",

  props: ["item"],

  data: () => ({
    loading: false,

    valid: true,
    required_rules: [(v) => !!v || "Field is required"],
    name_rules: [(v) => !!v || "Name is required"],

    menu_start_dt: false,
    menu_end_dt: false,

    // franchise
    franchise_loading: false,
    franchise_items: [],
    franchise_search: null,
    // select: null,

    // template
    template_loading: false,
    superseded_template_items: [],
    template_search: null,

    //cluster
    clusterLoading: false,
    clusterItems: [],
  }),

  methods: {
    async getAllSuspendedTemplated() {
      let service = new BaseService("templates");
      this.template_loading = true;
      let res = null;
      try {
        res = await service.get_all();
      } catch (e) {
        // nothing here
      } finally {
        this.template_loading = false;
      }
      this.superseded_template_items = res.data.results;
    },
    async getAllFranchises() {
      let service = new BaseService("franchises");
      this.franchise_loading = true;
      let res = null;
      try {
        res = await service.get_all();
      } catch (e) {
        // nothing here
      } finally {
        this.franchise_loading = false;
      }
      this.franchise_items = res.data.results;
    },
    async getClusterSublookup() {
      let service = new BaseService("sub-lookups");
      let res = null;

      let slectedFranchise = this.franchise_items.find(
        (item) => item.id === this.item.franchise_id
      );

      if (slectedFranchise.cluster_group_id) {
        this.clusterLoading = true;
        try {
          res = await service.get_all({
            lookup_id: slectedFranchise.cluster_group_id,
          });
        } catch (e) {
          // nothing here
        } finally {
          this.clusterLoading = false;
        }
        this.clusterItems = res.data.results;
      } else {
        this.item.cluster_group_sub_lookup_id = "";
        this.clusterItems = [];
      }
    },
    setBusinessType(val) {
      this.item.biz_type = this.franchise_items.find(
        (item) => item.id === val
      )?.business_activity;

      this.getClusterSublookup();
    },
    validate() {
      let result = this.$refs.form.validate();
      if (result) {
        this.save();
      }
    },
    reset() {
      this.$refs.form.reset();
    },
    async save() {
      this.loading = true;
      let res = null;
      if (this.crud_state == crud_state.ADD) {
        res = await templateService.create(this.item);
      } else {
        res = await templateService.update(this.item);
      }
      this.loading = false;
      if (res.status == 401) {
        this.handleLogout();
      } else if (res.status == 200 || res.status == 201) {
        this.$emit("edit_save", {
          msg: "Saved successfully",
          data: res.data,
        });
      } else {
        this.$emit("edit_error", { msg: "An error occurred, could not save" });
      }
    },

    async get_franchise_items(v) {
      this.franchise_loading = true;
      let res = null;
      try {
        res = await franchiseService.get_name_suggestions(v);
      } catch (e) {
        // nothing here
      } finally {
        this.franchise_loading = false;
      }
      return res.data.results;
    },

    async get_superseded_template_items(v) {
      this.template_loading = true;
      let res = null;
      try {
        res = await templateService.get_name_suggestions(
          this.item.franchise_id,
          v
        );
      } catch (e) {
        // nothing here
      } finally {
        this.template_loading = false;
      }
      return res.data.results;
    },

    get_default_franchise_options() {
      return this.item.franchise && this.item.franchise.id
        ? [
            { id: null, name: "None" },
            {
              id: this.item.franchise.id,
              name: this.item.franchise.name,
              cluster_group_id: this.item.franchise.cluster_group_id,
            },
          ]
        : [];
    },

    get_default_superseded_template_options() {
      return this.item.superseded_template && this.item.superseded_template.id
        ? [
            { id: null, name: "None" },
            {
              id: this.item.superseded_template.id,
              name: this.item.superseded_template.name,
            },
          ]
        : [];
    },

    handleLogout() {
      this.$store.dispatch("auth/logout");
    },

    formatDate(date) {
      if (!date) return null;

      const [year, month, day] = date.split("-");
      return `${month}/${day}/${year}`;
    },

    parseDate(date) {
      if (!date) return null;

      const [month, day, year] = date.split("/");
      return `${year}-${month.padStart(2, "0")}-${day.padStart(2, "0")}`;
    },
  },

  computed: {
    crud_state() {
      return this.item && this.item.id ? crud_state.EDIT : crud_state.ADD;
    },
  },

  watch: {
    item(/* val */) {
      this.franchise_items = this.franchise_items = this.get_default_franchise_options();
      this.superseded_template_items = this.get_default_superseded_template_options();
      this.getClusterSublookup();
    },

    franchise_search: debounce(async function(val) {
      if (val && val !== this.item.franchise.name) {
        try {
          this.franchise_items = [
            { id: null, name: "None" },
            ...(await this.get_franchise_items(val)),
          ];
        } catch (e) {
          // console.log(e);
        }
      }
    }, 500),

    template_search: debounce(async function(val) {
      if (val && val !== this.item.name) {
        try {
          this.superseded_template_items = [
            { id: null, name: "None" },
            ...(await this.get_superseded_template_items(val)),
          ];
        } catch (e) {
          // console.log(e);
        }
      }
    }, 500),
  },

  mounted() {
    if (this.item) {
      this.franchise_items = this.get_default_franchise_options();
      this.superseded_template_items = this.get_default_superseded_template_options();
      this.getClusterSublookup();
    }
  },
};
</script>
