<template>
  <div>
    <v-dialog
        v-model="dialog"
        max-width="450"
        @click:outside="toggleDialog"
        clossable
      >
        <v-card >
          <v-card-title>Delete</v-card-title>
            <v-card-text
              >Are you sure you want to delete `{{
                item_to_delete.name
              }}`?</v-card-text
            >
         <v-card-actions >
          <v-btn color="error" @click="confirm">Delete</v-btn>
          <v-btn color="primary" text @click="cancel"
            >Cancel</v-btn
          >
        </v-card-actions>
        </v-card>
      </v-dialog>
  </div>
</template>
<script>


export default ({
  name:'BooleanDialog',
  props: {
    dialog:Boolean,
    item_to_delete:Object,
  },
  methods: { 
    toggleDialog() { 
      this.$emit(`toggleDialog`)
    },
    confirm() {
      this.$emit(`confirmed`)
    },
    cancel() {
      this.$emit(`cancelled`)
    }
  }
  
})
</script>
