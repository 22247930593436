import axios from "axios";
import authHeader from "./auth-header";
import BaseService from "./base.service";

class TemplateService extends BaseService {
  constructor(url) {
    super(url);
  }

  get_name_suggestions(franchise_id, name) {
    let filters = { franchise_id: franchise_id, name: name };
    return axios.get(this.url + `/suggestions`, {
      headers: authHeader(this.token),
      params: filters,
    });
  }

  async update_product(obj) {
    let { template_id, product_id } = obj;
    try {
      let response = await axios.patch(
        this.url + `/${template_id}/products/${product_id}`,
        obj,
        { headers: authHeader() }
      );
      return response;
    } catch (error) {
      return error.response ? error.response : error;
    }
  }

  async get_product_sections_only(obj) {
    let param = { with_children: 0 };
    let { template_id, product_id } = obj;
    try {
      let response = await axios({
        url: this.url + `/${template_id}/products/${product_id}/sections`,
        method: "get",
        headers: authHeader(),
        params: param,
      });
      return response;
    } catch (error) {
      return error.response ? error.response : error;
    }
  }

  async get_product_sections_with_valid_sums(obj) {
    let param = { with_coverages: 0, franchisee_id: obj.franchiseeId };
    let { template_id, product_id } = obj;
    try {
      let response = await axios({
        url: this.url + `/${template_id}/products/${product_id}/sections`,
        method: "get",
        headers: authHeader(),
        params: param,
      });
      return response;
    } catch (error) {
      return error.response ? error.response : error;
    }
  }

  async save_order(type, params, data) {
    let newUrl;

    switch (type) {
      case "sections":
        newUrl = `${this.url}/${params.template_id}/products/${params.product_id}/sections/order`;
        break;
      case "sums":
        newUrl = `${this.url}/${params.template_id}/products/${params.product_id}/sections/${params.section_id}/sums/order`;
        break;
      case "endorsements":
        newUrl = `${this.url}/${params.template_id}/products/${params.product_id}/endorsements/order`;
        break;
    }
    try {
      let response = await axios({
        url: newUrl,
        method: "patch",
        headers: authHeader(),
        data: {
          [type]: data,
        },
      });
      return response;
    } catch (error) {
      return error.response ? error.response : error;
    }
  }

  getTemplatedSuggestions(filters) {
    return axios.get(this.url + `/suggestions`, {
      headers: authHeader(this.token),
      params: filters,
    });
  }
}

export default new TemplateService("templates");
