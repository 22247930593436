<template>
  <div class="pa-10">
    <v-form v-model="form" ref="form">
      <label for="name"> Name</label>
      <v-text-field
        outlined
        label="Endorsement Name"
        :rules="[(v) => !!v || 'Name is required']"
        v-model="endorsement.name"
        dense
      >
      </v-text-field>

      <label for="name">Description</label>
      <quill-editor v-model="endorsement.descr" />
      <div class="space-30"></div>
      <v-row>
        <v-col cols="3">
          <v-switch
            class="ml-4"
            inset
            hide-details
            v-model="endorsement.status"
            label="Status"
          ></v-switch
        ></v-col>
        <v-col cols="3">
          <label for="name">Rank</label>
          <v-text-field
            type="number"
            v-model="endorsement.rank"
            outlined
            label="Rank"
            dense
          >
          </v-text-field
        ></v-col>
      </v-row>
      <v-row>
        <v-col cols="3">
          <label for="name">Position Section</label>
          <v-select
            :items="endorsementSections"
            label="Position Section"
            v-model="positionSection"
            item-text="name"
            item-value="value"
            outlined
            @change="getPositions()"
            dense
          >
          </v-select
        ></v-col>
        <v-col cols="3">
          <v-switch
            class="ml-4"
            inset
            hide-details
            v-model="isTemplateDriven"
            label="Is TemplateDriven?"
            @change="getPositions"
          ></v-switch
        ></v-col>
        <v-col cols="3">
          <label for="name">Position Item No.</label>
          <v-text-field
            type="number"
            v-model="endorsement.position_item_no"
            outlined
            label="Position Item No."
            dense
          >
          </v-text-field
        ></v-col>
        <v-col cols="3">
          <label for="name">Position Items</label>
          <v-select
            :items="endorsementPositions"
            label="Position Section"
            v-model="endorsement.position_section"
            item-text="name"
            item-value="position"
            outlined
            dense
          >
          </v-select
        ></v-col>
        <v-col cols="3">
          <v-switch
            class="ml-4"
            inset
            hide-details
            v-model="endorsement.overwrite"
            label="Overwrite"
          ></v-switch
        ></v-col>
      </v-row>
      <div class="space-50"></div>
      <v-row class="justify-end">
        <v-btn color="success" @click="validateEndorsement">
          <v-icon small left>mdi-check</v-icon>
          Save
        </v-btn>
        <v-btn text @click="closeModal">
          Cancel
        </v-btn>
      </v-row>
    </v-form>
  </div>
</template>

<script>
import { quillEditor } from 'vue-quill-editor';
import BaseService from '@/services/base.service.js';

export default {
  name: 'AddEditEndorsement',
  data() {
    return {
      form: false,
      isTemplateDriven: false,
      positionSection: '',
      endorsementPositions: [],
    };
  },
  components: {
    quillEditor,
  },
  props: {
    endorsement: Object,
    endorsementSections: Array,
    templateId: Number,
    productId: Number,
  },
  mounted() {
    this.getPositions();
  },
  methods: {
    async getPositions() {
      let service = new BaseService('endorsements/positions');
      let params = {
        ...(this.positionSection && { section: this.positionSection }),
        ...(this.isTemplateDriven && {
          template: this.templateId,
          product: this.productId,
        }),
      };
      try {
        let res = await service.get_all(params);
        this.endorsementPositions = res.data;
      } catch (error) {
        console.log(`error`, error);
      }
    },
    validateEndorsement() {
      this.$refs.form.validate();
      if (this.form) {
        this.$emit(`saveEndorsement`);
      }
    },
    closeModal() {
      this.$emit(`closeEndorsementModal`);
    },
  },
};
</script>

<style lang="scss" scoped></style>
