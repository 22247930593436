<template>
  <div>
    <div class="commission-details">
      <h3>Commission & Fee Structure</h3>
      <v-divider class="mt-3 mb-5"></v-divider>

      <v-form v-model="isProductValid" ref="product-details">
        <v-simple-table>
          <template>
            <thead>
              <tr>
                <th class="text-left">
                  Edit Items
                </th>
                <th class="text-left" style="width:80px">
                  Add / Remove
                </th>
                <th class="text-left" style="width:300px">
                  Details Assigned
                </th>

                <th
                  class="text-left"
                  style="width:120px"
                  title="Commission (% New)"
                >
                  Commission<br />
                  (% New)
                </th>
                <th
                  class="text-left"
                  style="width:120px"
                  title="Commission (% Ren)"
                >
                  Commission<br />
                  (% Ren)
                </th>
              </tr>
              <tr>
                <th class="text-left" style="width:120px"></th>
                <th class="text-left" style="width:120px"></th>
                <th class="text-left" style="width:120px">
                  Fee <br />
                  (<v-icon small>mdi-currency-usd</v-icon>New)
                </th>
                <th class="text-left" style="width:120px">
                  Fee <br />
                  (<v-icon small>mdi-currency-usd</v-icon>Ren)
                </th>
                <th class="text-left" style="width:120px">
                  Fee <br />
                  (<v-icon small>mdi-currency-usd</v-icon>End)
                </th>
              </tr>
            </thead>
            <tbody>
              <template v-for="item in selectedProduct.fees">
                <tr :key="item.name">
                  <td rowspan="2">{{ item.name }}</td>
                  <td rowspan="2">
                    <v-switch v-model="item.isAdded"> </v-switch>
                  </td>
                  <template v-if="item.value == 'insurer'">
                    <td>
                      <v-select
                        outlined
                        dense
                        class="mt-3"
                        :disabled="!item.isAdded"
                        item-text="name"
                        item-value="value"
                        :items="insurerList"
                        :rules="checkMandatory(item, `selectedInsurer`)"
                        v-model="item.selectedInsurer"
                        label="Select Insurer"
                      >
                      </v-select>
                    </td>
                  </template>
                  <template v-else-if="item.value == 'broker'">
                    <td>
                      <v-select
                        outlined
                        dense
                        item-text="name"
                        item-value="value"
                        :disabled="!item.isAdded"
                        class="mt-3"
                        :items="brokerList"
                        :rules="checkMandatory(item, `selectedBroker`)"
                        v-model="item.selectedBroker"
                        label="Select Broker"
                      >
                      </v-select>
                    </td>
                  </template>
                  <template v-if="item.value == 'referer'">
                    <td>
                      <v-select
                        outlined
                        dense
                        item-text="name"
                        :disabled="!item.isAdded"
                        item-value="value"
                        class="mt-3"
                        :rules="checkMandatory(item, `selectedReferer`)"
                        :items="franchiseList"
                        v-model="item.selectedReferer"
                        label="Select Referer"
                      >
                      </v-select>
                    </td>
                  </template>
                  <td>
                    <v-text-field
                      class="mt-3"
                      type="number"
                      :disabled="!item.isAdded"
                      outlined
                      @change="updateTotal(`commission_new`)"
                      :rules="checkMandatory(item, `commission_new`)"
                      v-model="item.commission_new"
                      dense
                      label="% New"
                    >
                    </v-text-field>
                  </td>
                  <td>
                    <v-text-field
                      class="mt-3"
                      outlined
                      :disabled="!item.isAdded"
                      @change="updateTotal(`commission_ren`)"
                      :rules="checkMandatory(item, `commission_ren`)"
                      type="number"
                      dense
                      v-model="item.commission_ren"
                      label="% Ren"
                    >
                    </v-text-field>
                  </td>
                </tr>
                <tr :key="item.value">
                  <td>
                    <v-text-field
                      class="mt-3"
                      outlined
                      :disabled="!item.isAdded"
                      @change="updateTotal(`fee_new`)"
                      :rules="checkMandatory(item, `fee_new`)"
                      dense
                      type="number"
                      v-model="item.fee_new"
                      label="$ New"
                    >
                    </v-text-field>
                  </td>
                  <td>
                    <v-text-field
                      class="mt-3"
                      :disabled="!item.isAdded"
                      v-model="item.fee_ren"
                      @change="updateTotal(`fee_ren`)"
                      :rules="checkMandatory(item, `fee_ren`)"
                      outlined
                      dense
                      type="number"
                      label="$ Ren"
                    >
                    </v-text-field>
                  </td>
                  <td>
                    <v-text-field
                      class="mt-3"
                      v-model="item.fee_end"
                      :disabled="!item.isAdded"
                      :rules="checkMandatory(item, `fee_end`)"
                      @change="updateTotal(`fee_end`)"
                      outlined
                      dense
                      type="number"
                      label="$ End"
                    >
                    </v-text-field>
                  </td>
                </tr>
              </template>
              <tr>
                <td rowspan="2"></td>
                <td colspan="2">
                  <h3 class="text-right mt-n3">
                    Total Commision
                  </h3>
                </td>
                <td>
                  <v-text-field
                    class="mt-3"
                    v-model="selectedProduct.total_commission_new"
                    outlined
                    readonly
                    dense
                    type="number"
                    label="% New"
                  >
                  </v-text-field>
                </td>
                <td>
                  <v-text-field
                    class="mt-3"
                    v-model="selectedProduct.total_commission_ren"
                    outlined
                    readonly
                    dense
                    type="number"
                    label="% New"
                  >
                  </v-text-field>
                </td>
              </tr>
              <tr>
                <h4 class="text-right mt-5">Total Fees</h4>
                <td>
                  <v-text-field
                    class="mt-3"
                    :value="selectedProduct.total_fee_new"
                    outlined
                    readonly
                    dense
                    type="number"
                    label="$ New"
                  >
                  </v-text-field>
                </td>
                <td>
                  <v-text-field
                    class="mt-3"
                    :value="selectedProduct.total_fee_ren"
                    outlined
                    readonly
                    dense
                    type="number"
                    label="$ Ren"
                  >
                  </v-text-field>
                </td>
                <td>
                  <v-text-field
                    class="mt-3"
                    :value="selectedProduct.total_fee_end"
                    outlined
                    readonly
                    dense
                    type="number"
                    label="$ End"
                  >
                  </v-text-field>
                </td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
      </v-form>
    </div>
    <hr />
    <br />

    <div class="product-details ">
      <h3>Edit Product Details</h3>
      <v-divider class="mt-3 mb-5"></v-divider>
      <div class="d-flex px-5" style="width:250px">
        <p>Excess</p>
        <v-text-field
          class="mx-5"
          v-model="selectedProduct.excess"
          outlined
          dense
          type="number"
          label="$ Excess"
        >
        </v-text-field>
      </div>
      <v-row class="pa-5">
        <v-col cols="4" sm="2">
          Unique Market Reference
        </v-col>
        <v-col cols="8" sm="4">
          <v-text-field
            class="ml-5"
            v-model="selectedProduct.current_UMR"
            outlined
            dense
            label="Current (YOA)"
          >
          </v-text-field>
        </v-col>
        <v-col offset="4" offset-sm="0" cols="8" sm="4">
          <v-text-field
            class="ml-5"
            v-model="selectedProduct.future_UMR"
            outlined
            dense
            label="Future (YOA)"
          >
          </v-text-field>
        </v-col>
      </v-row>
    </div>
    <v-row class="justify-end">
      <v-btn
        v-if="isProductExisting != null || isProductExisting != undefined"
        color="success"
        @click="updateAssignedProduct"
      >
        Update Product
      </v-btn>
      <v-btn v-else color="success" @click="saveProduct">
        <v-icon small left >mdi-check</v-icon> 
        Save
      </v-btn>

      <v-btn text @click="closeProductDetails">
        Cancel
      </v-btn>
    </v-row>
    
    <div class="space-20"></div>
  </div>
</template>
<script>
export default {
  name: "TemplateProductMobile",
  data() {
    return {
      insurerList: [
        { name: "Dummy 1", value: "dummy-1" },
        { name: "Dummy 2", value: "dummy-2" },
        { name: "Dummy 3", value: "dummy-3" },
      ],
      brokerList: [
        { name: "Dummy 1", value: "dummy-1" },
        { name: "Dummy 2", value: "dummy-2" },
        { name: "Dummy 3", value: "dummy-3" },
      ],
      franchiseList: [
        { name: "Dummy 1", value: "dummy-1" },
        { name: "Dummy 2", value: "dummy-2" },
        { name: "Dummy 3", value: "dummy-3" },
      ],
      isProductValid: false,
    };
  },
  props: {
    selectedProduct: Object,
    isProductExisting: Number,
  },
  methods: {
    closeProductDetails() {
      this.$emit(`closeProductDetails`);
    },

    updateTotal(type) {
      let allTypes = this.selectedProduct.fees
        .filter((item) => item[type] && item[type])
        .map((item) => parseInt(item[type]));

      let total = allTypes.reduce((prev, cur) => prev + cur);
      let totalType = `total_${type}`;
      this.$set(this.selectedProduct, totalType, total);
    },
    saveProduct() {
      this.$refs[`product-details`].validate();
      if (this.isProductValid) this.$emit(`saveProduct`);
    },
    updateAssignedProduct() {
      this.$refs[`product-details`].validate();
      if (this.isProductValid) this.$emit(`updateAssignedProduct`);
    },
    checkMandatory(item, type) {
      if (item.isAdded) {
        if (item[type]) return;
        else return [`Required`];
      }
    },
  },
};
</script>

<style>
.v-data-table > .v-data-table__wrapper > table > thead > tr > th,
td {
  padding: 0 4px !important;
}

@media only screen and (max-width: 767px) {
  .v-stepper--vertical .v-stepper__step {
    padding: 24px 2px 14px !important;
  }
  .v-stepper--vertical .v-stepper__content {
    padding: 16px 40px 16px 4px !important;
  }
  .v-application--is-ltr .v-stepper--vertical .v-stepper__content {
    margin: -8px -36px -16px 14px !important;
  }
}
</style>
