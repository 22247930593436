<template>
  <div>
    <div class="assigned-products mb-1">
      <h3>Assigned Products</h3>
      <v-chip v-if="!mappedProducts || !mappedProducts.length"
        >No Product Found</v-chip
      >
      <v-chip-group
        v-model="isChipSelected"
        active-class="primary--text"
        column
      >
        <v-chip
          v-for="product in mappedProducts"
          :key="product.id"
          @click:close="removeProduct(product)"
          close
          @click="getProduct(product)"
          medium
        >
          {{ getProductNameById(product.product_id) }}
        </v-chip>
      </v-chip-group>
    </div>

    <a @click="addNewProduct"
      ><v-icon color="blue" small>mdi-plus</v-icon>Add More Product</a
    >
    <div class="space-30"></div>
    <div class="add-product" v-if="isAddProduct">
      <h3 class="mb-3">Add More Products</h3>
      <v-select
        v-model="selectedProduct.product_id"
        :items="productsList"
        item-text="name"
        item-value="id"
        outlined
        clearable
        @change="assignProduct($event)"
        dense
        label="Select Product"
      ></v-select>
    </div>
     <v-card v-if="loading">
        <v-skeleton-loader
          v-bind="$attrs"
          type="table-heading, list-item-two-line, image"
        ></v-skeleton-loader>
    </v-card>
    <div
      v-else-if="selectedProduct && selectedProduct.product_id"
      class="pa-5"
      id="table-media"
    >
      <v-tabs centered  v-model="productDetailsTab">
        <v-tab href="#commission">Commission & Fee Structure</v-tab>
        <v-tab href="#sections">Sections & Sums</v-tab>
        <v-tab href="#endorsements">Endorsements</v-tab>
      <v-tab-item id="commission">
        <div class="space-30"></div>
        <template>
          <ProductCommission
            v-if="isScreenLarge"
            @closeProductDetails="resetSelectedProduct"
            :selectedProduct="selectedProduct"
            :selectedAgent="selectedAgent"
            :isProductExisting="isChipSelected"
            :productsList="productsList"
            @saveProduct="saveProduct"
            @updateAssignedProduct="updateAssignedProduct"
          />
          <ProductCommissionMobile
            v-else
            @closeProductDetails="resetSelectedProduct"
            :selectedProduct="selectedProduct"
            :selectedAgent="selectedAgent"
            :productsList="productsList"
            :isProductExisting="isChipSelected"
            @saveProduct="saveProduct"
            @updateAssignedProduct="updateAssignedProduct"
          />
        </template>
        
      </v-tab-item>
      <v-tab-item id="sections">
        <div class="space-30"></div>
          <ProductSectionsSums
          :selectedProductSections="selectedProductSections"
          :templateId="templateId"
          :productId="selectedProduct.product_id"
          @sectionOrderSaved="selectedProductSections = $event"
          />
      </v-tab-item>
      <v-tab-item id="endorsements">
        <div class="space-30"></div>
          <ProductEndorsements 
            :productEndorsements="productEndorsements"
            :selectedProduct="selectedProduct"
          />
      </v-tab-item>
      </v-tabs>

      <br /><br />
    </div>

    <Snackbar
      class="mb-10"
      v-if="snackbar.show"
      :show="snackbar.show"
      :text="snackbar.text"
      :color="snackbar.color"
    ></Snackbar>

    <BooleanDialog
      :dialog="deleteDialog"
      :type="`Product`"
      :item_to_delete="selectedProduct"
      @confirmed="deleteAssignedProduct"
      @toggleDialog="deleteDialog = !deleteDialog"
      @cancelled="deleteDialog = false"
    >
    </BooleanDialog>
  </div>
</template>
<script>
import ProductCommission from './template-product/ProductCommission';
import ProductCommissionMobile from './template-product/ProductCommissionMobile';
import TemplateProduct from '@/models/template-product';
import Snackbar from '@/components/common/Snackbar';
import BooleanDialog from '@/components/common/BooleanDialog';
import BaseService from '@/services/base.service.js';
import TemplateService from '@/services/template.service.js';
import ProductSectionsSums from './template-product/ProductSectionsSums';
import ProductEndorsements from './template-product/ProductEndorsements';

export default {
  name: "EditTemplateProduct",
  data() {
    return {
      isAddProduct: false,
      loading:false,
      snackbar: {},
      mappedProducts: [],
      selectedAgent: {},
      productsList: [],
      productEndorsements:[],
      isChipSelected: null,
      deleteDialog: false,
      isScreenLarge: false,
      selectedProduct: new TemplateProduct(),
      tab: null,
      selectedProductSections:[],
      productDetailsTab:null,
    };
  },
  components: {
    ProductCommissionMobile,
    Snackbar,
    BooleanDialog,
    ProductCommission,
    ProductSectionsSums,
    ProductEndorsements
  },
  created() {
    window.addEventListener("resize", this.handleResize);
    this.handleResize();
  },
  props: {
    templateId:Number,
    step:Number,
  },
  watch: {
    step(val) {
      if(val == 3) {
        this.getProductsList();
        this.getMappedProducts();
      }
    },
    productDetailsTab(val){
      this.setAgentsForExistingProduct();
      if(val == 'sections') {
        let product = { 
          template_id : this.templateId,
          product_id : this.selectedProduct.product_id
        }
        this.getProductSectionsOnly(product)
      }
    }
  },
  methods: {
    addNewProduct() {   
      this.isAddProduct = true;
      this.selectedProduct = new TemplateProduct();
    },
    async getProductsList() {
      let type_id = 1;
      let productService = new BaseService(`lookups/type/${type_id}`);
      let res = await productService.get_all();
      if(res) {
        this.productsList = res.data.results.filter(product => {
          return product.id == 1 || (product.id >= 43 && product.id <= 200) 
        });
      }
    },
    getProductNameById(id) {
      if(this.productsList) {
        let indexed = this.productsList.findIndex(product => product.id == id)
        if(indexed >= 0){
          return this.productsList[indexed].name;
        }
      }
    },
    async getMappedProducts() {
      let service = new BaseService(`templates/${this.templateId}/products`);
      let res = await service.get_all()
      if(res) {
        this.mappedProducts = res.data.template_products
      }
    },
    assignProduct(id) {
      this.isChipSelected = null;
      let productName, filteredName;
      filteredName = this.productsList.filter((item) => item.id == id)
      if(filteredName.length > 0) productName = filteredName[0].name
      if (this.mappedProducts) {
        let index = this.mappedProducts.findIndex(
          (item) => item.product_id == id
        );
        if (index >= 0) {
          this.snackbar = {
            show: true,
            text: `product ${productName} is Already assigned to this Template`,
            color: "red",
          };
          this.resetSnackbar();
          this.selectedProduct = new TemplateProduct();
        } else {
          this.selectedProduct = new TemplateProduct();
          this.selectedProduct.product_id = id; 
        }
      }
    },
    async getProductCommissionDetails(product) { 
      this.loading = true;
      this.isAddProduct = true;
      let service = new BaseService(`templates/${this.templateId}/products`);
      let res = await service.get_one(product.product_id)
      if(res) {
        this.selectedProduct = res.data.template_product;
        this.setAgentsForExistingProduct();
        this.loading = false;
      }
    },
    async getProductSectionsOnly(product) { 
      this.loading = true;
      let res = await TemplateService.get_product_sections_only(product);
      if(res) {
        this.selectedProductSections = res.data.sections && res.data.sections.map(section => section.section)
        this.loading = false;
      } 
    },
    getProduct(product) {
      this.getProductCommissionDetails(product);
    },
    setAgentsForExistingProduct() {
      setTimeout(() => {
        let allFees = this.selectedProduct.fees;
        allFees.forEach((fee) => {
          if (fee.party_type === 1)
            this.$set(this.selectedAgent, 'insurer', fee.party_name);
          if (fee.party_type === 2)
            this.$set(this.selectedAgent, 'broker', fee.party_name);
          if (fee.party_type === 3)
            this.$set(this.selectedAgent, 'franchise', fee.party_name);
        });
      }, 200);
    },
    async saveProduct(state) {
      let validation = this.validateProduct();
      if(validation) { 
         let service,res,url = `templates/${this.templateId}/products`;

          if (state == 'create') {
            service = new BaseService(url);
            res = await service.create(this.selectedProduct);
          } else {
            res = await TemplateService.update_product(this.selectedProduct);
          }

          if (res.status == 401) {
            this.handleLogout();
          } else if (res.status == 200 || res.status == 201) {
            this.$store.dispatch('common/setFlashMsg', 'Saved successfully');
            this.getMappedProducts();
          } else {
            this.$store.dispatch(
              'common/setFlashMsg',
              'An error occured, could not save'
            );
          }
      }
    },
    validateProduct() { 
      let feeStatuses = this.selectedProduct.fees.filter(fee => fee.status === true)
      if(feeStatuses.length < 1) { 
        this.snackbar = {
            show: true,
            text: `Please add atleast Insurer or  Broker for this Product`,
            color: 'red',
          };
          this.resetSnackbar();
      }  else { 
        return true;
      }
    },
    updateAssignedProduct() {
      let index = this.mappedProducts.findIndex(
        (item) => item.product_id == this.selectedProduct.product_id
      );
      this.mappedProducts[index] = this.selectedProduct;
      this.resetSelectedProduct();
    },
    resetSnackbar() {
      setTimeout(() => {
        this.snackbar = {};
      }, 2000);
    },
    resetSelectedProduct() {
      this.selectedProduct = new TemplateProduct();
      this.isChipSelected = null;
      this.isAddProduct = false;
    },
    removeProduct(product) {
      this.deleteDialog = true;
      this.selectedProduct.product_id = product.product_id;
      this.selectedProduct.name = this.productsList.filter(
        (item) => item.id == product.product_id
      )[0].name;
    },
    async deleteAssignedProduct() {
      let service = new BaseService(`templates/${this.templateId}/products`);
      let res = await service.delete(this.selectedProduct.product_id);

      if (res.status == 401) {
        this.handleLogout();
      } else if (res.status == 200 || res.status == 201) {
        this.$store.dispatch('common/setFlashMsg', 'Saved successfully');
        this.deleteDialog= false;
        this.resetSelectedProduct();
        this.getMappedProducts();
      } else {
        this.$store.dispatch('common/setFlashMsg', 'An error occured, could not save');
      }
    },
    handleResize() {
      this.isScreenLarge = window.innerWidth > 1023;
    },
    destroyed() {
      window.removeEventListener("resize", this.handleResize);
    },
  }
};
</script>
<style>
.no-link {
  text-decoration: none !important;
  color: black !important;
}

@media only screen and (max-width: 1023px) {
  #table-media {
    padding: 0 !important;
  }
}
</style>
