<template>
  <div>
    <h1 class="page-title">Template Manager</h1>

    <SimpleTable
      :headers="headers"
      :items="items"
      :loading="loading"
      :searchLoading="searchLoading"
      :expanded_headers="expanded_headers"
      :totalCount="totalCount"
      url_fragment="templates"
      @hitPagination="get_items"
      @hitSearch="fetchSearchedData"
      @add_item_click="add_item_click"
      @edit_item_click="edit_item_click"
    />
  </div>
</template>

<script>
import { crud_state } from "@/lib/constants";
import SimpleTable from "@/components/common/SimpleTable.vue";
import BaseService from "@/services/base.service.js";
import TemplatessService from "@/services/template.service.js";

export default {
  data() {
    return {
      // for list view
      headers: [
        {
          text: "Template",
          align: "start",
          // filterable: false,
          value: "name",
        },
        { text: "Franchise", value: "franchise_name" },
        { text: "End Date", value: "end_dt" },
        { text: "Status", value: "status" },
        { text: "Actions", value: "actions", sortable: false },
        { text: "", value: "data-table-expand" },
      ],
      items: [],
      expanded_headers: [
        { text: "Start Date", value: "start_dt" },
        { text: "Expiry Logic", value: "expiry_logic" },
        { text: "Expiry Min Length", value: "expiry_logic_min_len" },
        { text: "Email Insurer", value: "email_insurer" },
        { text: "Email Broker", value: "email_broker" },
        { text: "Email Agent", value: "email_agent" },
      ],
      totalCount: 0,

      // for add new / edit
      item_to_edit: null,

      crud_state: crud_state.LIST, // can be list, edit, addnew
      crud_id: null, // id crud is actioning upon

      // generic
      loading: false,
      searchLoading: false,
      search: "",
      paginations: {},
    };
  },

  methods: {
    async fetchSearchedData(search) {
      if (search) {
        const limit = this.paginations.itemsPerPage;
        const offset =
          (this.paginations.page - 1) * this.paginations.itemsPerPage + 1;

        this.searchLoading = true;
        let filters = { limit: limit, start: offset, search };

        try {
          let res = await TemplatessService.getTemplatedSuggestions(filters);
          this.totalCount = res.data.count;
          this.items = this.transform_to_row_item(res.data.results);
        } catch (e) {
          console.error(e);
        } finally {
          this.searchLoading = false;
        }
      } else {
        this.get_items(this.paginations);
      }
    },
    async get_items(pagination = { itemsPerPage: 10, page: 1 }) {
      this.paginations = { ...pagination };
      this.loading = true;
      const limit = pagination.itemsPerPage;
      const offset = (pagination.page - 1) * pagination.itemsPerPage + 1;
      let filters = { limit: limit, start: offset };

      let service = new BaseService("templates");

      try {
        let res = await service.get_all(filters);
        this.totalCount = res.data.count;
        this.items = this.transform_to_row_item(res.data.results);
      } catch (e) {
        if (e.response.status === 401) {
          this.$store.dispatch("auth/logout");
        }
        console.error(e);
      } finally {
        this.loading = false;
      }
    },

    // select items needed in table
    transform_to_row_item(items) {
      let transformed_items = items.map((item) => {
        item.start_dt = item.start_dt ? this.to_short_date(item.start_dt) : "";
        item.end_dt = item.end_dt ? this.to_short_date(item.end_dt) : "";
        item.franchise_name =
          item.franchise && item.franchise.name ? item.franchise.name : "";
        return item;
      });
      return transformed_items;
    },

    async edit_item_click(id) {
      if (id || id === 0) {
        this.$router.push(`/templates/edit/${id}`);
      } else {
        this.edit_error("Invalid data passed");
      }
    },

    add_item_click() {
      this.$router.push("/templates/add");
    },

    error(payload) {
      this.show_edit_form = false;
      this.$store.dispatch("common/setFlashMsg", payload.msg);
    },

    to_short_date(dt) {
      return new Date(dt).toLocaleDateString("en-GB", {
        day: "numeric",
        month: "short",
        year: "numeric",
      });
    },
  },

  computed: {
    show_edit_form: {
      get: function() {
        return (
          this.crud_state == crud_state.ADD ||
          this.crud_state == crud_state.EDIT
        );
      },

      set: function(newValue) {
        if (!newValue) {
          this.crud_state = crud_state.LIST;
        }
      },
    },
  },

  mounted() {
    this.get_items();
  },

  components: { SimpleTable },
};
</script>

<style lang="scss" scoped></style>
