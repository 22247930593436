<template>
  <div class="pa-7">
    <h1 class="page-title">Add / Edit Template</h1>

    <v-stepper v-model="e6"  >
      <v-stepper-header>
        <v-stepper-step :editable="item && item.id ? true : false" :step="1">
          Basic info
          <small class="d-none d-lg-flex d-xl-none" >Link to franchise, and other basic information</small>
        </v-stepper-step>
          <v-stepper-step :editable="item && item.id ? true : false"  :step="2">
          Link broker
        </v-stepper-step>
      <v-stepper-step :editable=" item && item.id ? true : false" :step="3">
          Add products
          <small class="d-none d-lg-flex d-xl-none"> Including commissions & fees</small>
        </v-stepper-step>
      </v-stepper-header>
      <v-stepper-items>
        <v-stepper-content step="1" class="pa-0">
            <TemplateBasicEdit
              class="pa-5"
              :item="item"
              @edit_save="edit_save"
              @edit_error="edit_error"
            />

          <v-footer paddless class="py-4">
            <v-row class="justify-end my-2">
             <v-btn color="primary"  @click="e6 = 2">
                Continue
                 <v-icon>mdi-chevron-right</v-icon>
              </v-btn>
              <v-btn @click="cancel" text>
                Cancel
              </v-btn>
            </v-row>
          </v-footer>


        </v-stepper-content>

         <v-stepper-content step="2" class="pa-0">
            <TemplateBrokerEdit
                class="pa-5"
                :template="item"
                @edit_save="edit_save"
                @edit_error="edit_error"
              />
              
             <v-footer paddless class="py-2">
                <v-row class="my-2">
                  <v-col :cols="isSizeSmall ? 12 : 4" >
                    <v-btn :block="isSizeSmall"  text @click="e6 -= 1">
                      <v-icon>mdi-chevron-left</v-icon> Back</v-btn
                    >
                  </v-col>
                  <v-col :cols="isSizeSmall ? 12 : 8"  >
                    <v-row class="justify-end pt-3" :class="isSizeSmall ? 'px-10' : ''">
                      <v-btn :block="isSizeSmall" color="primary" @click="e6 = 3">
                      Continue
                      <v-icon>mdi-chevron-right</v-icon>
                    </v-btn>

                      <v-btn  :block="isSizeSmall"  @click="cancel" text>
                        Cancel
                      </v-btn>
                    </v-row>
                  </v-col>
              </v-row>
            </v-footer>

          </v-stepper-content>

          <v-stepper-content step="3" class="pa-0">
            <TemplateProductEdit
              class="pa-5"
              :step="e6"
              :templateId="item && item.id"
          />
           <v-footer paddless class="py-2">
                <v-row class="my-2">
                  <v-col :cols="isSizeSmall ? 12 : 4" >
                    <v-btn :block="isSizeSmall"  text @click="e6 -= 1">
                      <v-icon>mdi-chevron-left</v-icon> Back</v-btn
                    >
                  </v-col>
                  <v-col :cols="isSizeSmall ? 12 : 8"  >
                    <v-row class="justify-end pt-3" :class="isSizeSmall ? 'px-10' : ''">
                      <v-btn :block="isSizeSmall" color="primary" @click="e6 = 3">
                      Continue
                      <v-icon>mdi-chevron-right</v-icon>
                    </v-btn>

                      <v-btn  :block="isSizeSmall"  @click="cancel" text>
                        Cancel
                      </v-btn>
                    </v-row>
                  </v-col>
              </v-row>
            </v-footer>
          </v-stepper-content>
      </v-stepper-items>
    </v-stepper>
  </div>
</template>

<script>
import Template from "@/models/template";
import templateService from "@/services/template.service.js";
import TemplateBasicEdit from "./TemplateBasicEdit";
import TemplateBrokerEdit from "./TemplateBrokerEdit";
import TemplateProductEdit from "./TemplateProductEdit.vue";

export default {
  name: "edit-template",

  props: ["id"],

  data() {
    return {
      e6: 1,
      item: null,
      // crud_state: null,
    };
  },
  computed: { 
    isSizeSmall(){
      if(this.$vuetify.breakpoint.width < 800) return true;
      else return false;
    }
  },
  methods: {
    async get_item(id) {
      if (!id) {
        return new Template();
      }
      this.loading = true;
      let res = await templateService.get_one(id);
      this.loading = false;
      return res.data;
    },

    // triggered when basic info section has been saved
    async edit_save(payload) {
      this.$store.dispatch("common/setFlashMsg", payload.msg);
      this.item = { ...this.item, ...payload.data };
      // move to next step
      this.e6++;
    },

    async finish_save(/* item */) {
      this.show_edit_form = false;
      this.$store.dispatch("common/setFlashMsg", "Saved successfully");
      // refresh data
      this.items = await this.get_item(this.id);
    },

    edit_error(payload) {
      this.show_edit_form = false;
      this.$store.dispatch("common/setFlashMsg", payload.msg);
    },

    cancel() {
      this.$router.push("/templates/list");
    },
  },

  watch: {
    id: async function(newValue) {
      this.item = await this.get_item(newValue);
    },
  },

  async mounted() {
    this.item = await this.get_item(this.id);
  },

  components: { TemplateBasicEdit, TemplateBrokerEdit, TemplateProductEdit },
};
</script>