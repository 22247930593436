export default class Template {
    constructor() {
    this.id = null;
    this.name = null;
    this.descr = null;
    this.status = null;
    this.franchise_id = null;
    this.franchise = {};
    this.biz_type = null;
    this.start_dt = null;
    this.end_dt = null;
    this.expiry_logic = null;
    this.expiry_logic_min_len = null;
    this.expiry_type = null;
    this.notice_days = null;
        
    this.superseded_template_id = null;
    this.superseded_template = {};
    this.force_policy_num_change_on_renewal = null;
    this.referral_emails = null;
    this.email_insurer = null;
    this.email_broker = null;
    this.email_agent = null;
    this.formal_policy_num = null;
    this.created_dt = null;
    this.updated_dt = null;
        
  }
}
