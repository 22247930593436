<template>
  <div class="pa-15">
    <v-row>
      <v-col cols="12" lg="3">
        <p>Sum Name</p>
      </v-col>

      <v-col cols="12" lg="9">
        <v-text-field
          :label="`Enter Sum Name`"
          v-model="selectedSum.name"
          outlined
          dense
        ></v-text-field>
      </v-col>
    </v-row>
    <p><b>Sum Description</b></p>
    <!-- <vue-editor 
      v-model="selectedSum.descr" 
      :disabled="!isRoleSuperAdmin" 
     >
    </vue-editor> -->
    <quill-editor v-model="selectedSum.descr" />
    <div class="space-20"></div>
    <v-row v-if="selectedSum.sum_type !== 'no_value'">
      <v-col cols="12" lg="3">
        <v-switch
          class="ml-4"
          v-model="selectedSum.is_dependent_on_sum"
          @change="dependentSelected(selectedSum.is_dependent_on_sum)"
          inset
          hide-details
          label="Dependent on Sum"
        ></v-switch>
      </v-col>
      <v-col cols="12" lg="9">
        <div class="space-10"></div>
        <v-select
          :disabled="!selectedSum.is_dependent_on_sum"
          :items="sumsForDependency"
          label="Select Sum Dependent"
          v-model="selectedSum.dependent_on_sum_id"
          :readonly="!isRoleAdmin && !isRoleSuperAdmin"
          item-text="name"
          item-value="id"
          outlined
          dense
        >
        </v-select>
      </v-col>
    </v-row>
    <v-row class="mb-4">
      <v-col cols="12" lg="3">
        <v-switch
          class="ml-4 mt-0"
          v-model="selectedSum.status"
          inset
          hide-details
          label="Status"
        ></v-switch>
      </v-col>
      <v-col cols="12" lg="3" v-if="selectedSum.sum_type !== 'no_value'">
        <v-switch
          class="ml-4 mt-0"
          v-model="selectedSum.is_mandatory"
          inset
          hide-details
          label="Is Mandatory"
        ></v-switch>
      </v-col>
    </v-row>
    <div class="space-30"></div>
    <v-row>
      <v-col cols="12" lg="3">
        <p>Sum Type</p>
      </v-col>
      <v-col cols="12" lg="9">
        <v-select
          :items="sumTypes"
          label="Select Sum Type"
          v-model="selectedSum.sum_type"
          :readonly="!isRoleAdmin && !isRoleSuperAdmin"
          :rules="[(v) => !!v || `Sum Type is Required`]"
          item-text="name"
          item-value="value"
          outlined
          dense
        >
        </v-select>
      </v-col>
    </v-row>
    <v-row v-if="selectedSum.sum_type !== 'no_value'">
      <v-col cols="12" lg="3">
        <p>Referral Limit</p>
      </v-col>

      <v-col cols="12" lg="9">
        <v-text-field
          :label="`Enter Referral Limit`"
          v-model="selectedSum.referral_limit"
          outlined
          dense
        ></v-text-field>
      </v-col>
    </v-row>

    <v-row v-if="selectedSum.sum_type !== 'no_value'">
      <v-col cols="12" lg="3">
        <p>Winbeat Tag Name</p>
      </v-col>

      <v-col cols="12" lg="9">
        <!-- <p>Winbeat Tag Name</p> -->
        <v-text-field
          :label="`Enter Winbeat Tag Name`"
          v-model="selectedSum.wb_tag_name"
          outlined
          dense
        ></v-text-field>
      </v-col>
    </v-row>

    <template v-if="selectedSum.sum_type !== 'no_value'">
      <p><b>Sum Parameters</b></p>
      <div class="d-lg-flex">
        <v-col
          cols="12"
          lg="3"
          v-for="(val, prop, index) in selectedSum.sum_params"
          :key="index"
        >
          <p>{{ prop | capitalize }}</p>
          <v-text-field
            :label="`Enter value`"
            type="number"
            :readonly="!isRoleAdmin && !isRoleSuperAdmin"
            v-model.number="selectedSum.sum_params[prop]"
            outlined
            @input="
              selectedSum.sum_params[prop] = Math.abs(
                selectedSum.sum_params[prop]
              )
            "
            min="0"
            clearable
            dense
          ></v-text-field>
        </v-col>
      </div>

      <template v-if="selectedSum.sum_type == 'basic'">
        <p><b>Sum Rates</b></p>
        <v-row>
          <v-col cols="12" lg="3">
            <p>Basic Rate</p>
          </v-col>
          <v-col cols="12" lg="3">
            <v-text-field
              type="number"
              prefix="$"
              :readonly="!isRoleAdmin && !isRoleSuperAdmin"
              v-model.number="selectedSum.amount"
              :disabled="selectedSum.rate ? true : false"
              @input="
                selectedSum.amount = selectedSum.amount
                  ? Math.abs(selectedSum.amount)
                  : selectedSum.amount
              "
              min="0"
              outlined
              clearable
              dense
            ></v-text-field>
          </v-col>
          <v-col cols="12" lg="3">
            <v-text-field
              :label="`%`"
              type="number"
              :readonly="!isRoleAdmin && !isRoleSuperAdmin"
              clearable
              :disabled="selectedSum.amount ? true : false"
              v-model.number="selectedSum.rate"
              @input="
                selectedSum.rate = selectedSum.rate
                  ? Math.abs(selectedSum.rate)
                  : selectedSum.rate
              "
              min="0"
              outlined
              dense
            ></v-text-field>
          </v-col>
        </v-row>
      </template>

      <template
        v-else-if="
          selectedSum.sum_type == 'complex' ||
            selectedSum.sum_type == 'complex_fixed'
        "
      >
        <p>
          <b>Sum Rates</b>
          <v-btn text color="primary" @click="addThreshold()"
            ><v-icon>mdi-plus</v-icon> Add Threshold</v-btn
          >
        </p>
        <div class="space-20"></div>
        <v-row
          class="mt-n5"
          v-for="(threshold, index) of selectedSum.thresholds"
          :key="threshold.value"
        >
          <v-col cols="12" lg="3">
            <p>
              {{
                threshold.id
                  ? `Threshold ${threshold.id}`
                  : `Threshold ${index + 1}#`
              }}
            </p>
          </v-col>
          <v-col cols="12" lg="2">
            <v-text-field
              label="From"
              type="number"
              :readonly="!isRoleAdmin && !isRoleSuperAdmin"
              clearable
              v-model.number="threshold.thrsh_from"
              @change="thresholdValidate(threshold)"
              @input="threshold.thrsh_from = Math.abs(threshold.thrsh_from)"
              min="0"
              outlined
              dense
            >
            </v-text-field>
          </v-col>
          <v-col cols="12" lg="2">
            <v-text-field
              label="To"
              type="number"
              clearable
              :disabled="selectedSum.sum_type == 'complex_fixed'"
              :readonly="!isRoleAdmin && !isRoleSuperAdmin"
              @change="thresholdValidate(threshold)"
              v-model.number="threshold.thrsh_to"
              @input="threshold.thrsh_to = Math.abs(threshold.thrsh_to)"
              min="0"
              outlined
              dense
            >
            </v-text-field>
          </v-col>
          <v-col cols="12" lg="2">
            <v-text-field
              type="number"
              clearable
              :readonly="!isRoleAdmin && !isRoleSuperAdmin"
              v-model.number="threshold.amount"
              @change="thresholdValidate(threshold)"
              @input="threshold.amount = Math.abs(threshold.amount)"
              min="0"
              :disabled="threshold.rate ? true : false"
              outlined
              dense
              prefix="$"
            ></v-text-field>
          </v-col>
          <v-col cols="12" lg="2">
            <v-text-field
              label="%"
              type="number"
              :readonly="!isRoleAdmin && !isRoleSuperAdmin"
              clearable
              :disabled="threshold.amount ? true : false"
              @change="thresholdValidate(threshold)"
              v-model.number="threshold.rate"
              @input="threshold.rate = Math.abs(threshold.rate)"
              min="0"
              outlined
              dense
            ></v-text-field>
          </v-col>
          <v-col cols="12" lg="1">
            <v-icon @click="deleteThreshold(index)">mdi-delete</v-icon>
          </v-col>
        </v-row>
      </template>

      <v-row
        v-if="
          selectedSum.sum_type == 'complex_fixed' && selectedSum.thresholds[0]
        "
      >
        <v-col cols="12" lg="3">
          <p>Min ACCA value</p>
        </v-col>
        <v-col cols="12" lg="9">
          <v-radio-group
            v-model="accaMinThresholdId"
            @change="addAccaMinThresholdId()"
            row
            style="padding-top: 0; margin-top:0"
          >
            <v-radio
              v-for="(option, index) in selectedSum.thresholds"
              :key="index"
              :label="
                option.id ? `Threshold ${option.id}` : `Threshold ${index + 1}#`
              "
              :value="option"
              :disabled="!option.thrsh_from"
            ></v-radio>
          </v-radio-group>
        </v-col>
      </v-row>

      <p class="mt-2"><b>Sum Tax Settings</b></p>
      <div class="d-lg-flex">
        <v-row>
          <v-col
            cols="12"
            lg="4"
            v-for="(val, prop, index) in selectedSum.sum_tax_settings"
            :key="index"
          >
            <p>{{ prop | formatTaxSettings | capitalize }}</p>
            <v-switch
              v-model="selectedSum.sum_tax_settings[prop]"
              label="yes"
            ></v-switch>
          </v-col>
        </v-row>
      </div>
    </template>

    <div class="space-30"></div>
    <v-row class="justify-end" v-if="isRoleAdmin || isRoleSuperAdmin">
      <v-btn color="success" @click="validateSum">
        <v-icon small left>mdi-check</v-icon>
        Save
      </v-btn>
      <v-btn text @click="closeSum()">
        Cancel
      </v-btn>
    </v-row>
    <div class="space-20"></div>
    <Snackbar
      class="mb-10"
      v-if="snackbar.show"
      :show="snackbar.show"
      :text="snackbar.text"
      :color="snackbar.color"
    ></Snackbar>
  </div>
</template>
<script>
import Snackbar from "@/components/common/Snackbar";
import BaseService from "@/services/base.service.js";
import { quillEditor } from "vue-quill-editor";
// import { VueEditor } from 'vue2-editor';

export default {
  name: "ProductSumDetails",
  data() {
    return {
      sumTypes: [
        { name: "Basic", value: "basic" },
        { name: "Complex", value: "complex" },
        { name: "Complex Fixed", value: "complex_fixed" },
        { name: "No Value", value: "no_value" },
      ],
      snackbar: {},
      validations: {},
      accaMinThresholdId: "",
    };
  },
  components: {
    Snackbar,
    quillEditor,
    // VueEditor
  },
  filters: {
    capitalize(val) {
      let lower = val.toLowerCase();
      return lower.charAt(0).toUpperCase() + lower.slice(1);
    },

    formatTaxSettings(val) {
      let arr = val.split("_");
      return arr.join(" ");
    },
  },
  computed: {
    sumsForDependency() {
      if (this.selectedSum) {
        return this.allSectionSums.filter(
          (item) => item.id != this.selectedSum.id
        );
      } else {
        return this.allSectionSums;
      }
    },
  },
  props: {
    selectedSum: Object,
    isRoleSuperAdmin: Boolean,
    isRoleAdmin: Boolean,
    allSectionSums: Array,
  },
  watch: {
    "selectedSum.sum_type"() {
      if (
        (this.selectedSum.sum_type == "complex" ||
          this.selectedSum.sum_type == "complex_fixed") &&
        this.selectedSum.thresholds &&
        this.selectedSum.thresholds.length < 1
      ) {
        this.prepareThreshold();
      }
    },
  },
  mounted() {
    this.accaMinThresholdId = this.selectedSum.thresholds.find(
      (item) => item.is_acca_min
    );
  },
  methods: {
    deleteThreshold(index) {
      if (this.selectedSum.thresholds.length > 1) {
        this.selectedSum.thresholds.splice(index, 1);
      } else {
        this.snackbar = {
          show: true,
          text: `Atleast 1 threshold is required`,
          color: `red`,
        };
      }
    },
    addThreshold() {
      this.selectedSum.thresholds.push({
        name: `Threshold ${this.selectedSum.thresholds.length + 1}`,
        value: `threshold${this.selectedSum.thresholds.length + 1}`,
        from: null,
        to: null,
        rate: null,
        amount: null,
        isError: false,
      });
    },
    dependentSelected(isDependent) {
      if (!isDependent) this.selectedSum.dependent_on_sum_id = null;
    },
    addAccaMinThresholdId() {
      this.selectedSum.thresholds.forEach((item) => {
        item.is_acca_min = item.id
          ? item.id === this.accaMinThresholdId.id
          : item.name === this.accaMinThresholdId.name;
      });
    },
    checkRatesValidation() {
      if (!this.selectedSum.status) return true;

      if (this.selectedSum.sum_type == "basic") {
        if (
          (this.selectedSum.rate === null || this.selectedSum.rate === "") &&
          (this.selectedSum.amount === null || this.selectedSum.amount === "")
        ) {
          this.snackbar = {
            show: true,
            text: `Please add the rate or percentage of the sum`,
            color: `red`,
          };

          this.resetSnackbar();
          return;
        }
      } else if (
        this.selectedSum.sum_type == "complex" ||
        this.selectedSum.sum_type == "complex_fixed"
      ) {
        let thresholdFilters;
        if (this.selectedSum.sum_type == "complex") {
          thresholdFilters = this.selectedSum.thresholds.filter((threshold) => {
            return (
              threshold.thrsh_from !== null ||
              threshold.thrsh_to !== null ||
              threshold.rate ||
              threshold.amount
            );
          });
        } else {
          thresholdFilters = this.selectedSum.thresholds.filter((threshold) => {
            return (
              threshold.thrsh_from !== null ||
              threshold.rate ||
              threshold.amount
            );
          });
        }
        // When no threshold is entered
        if (thresholdFilters.length < 1) {
          this.snackbar = {
            show: true,
            text: `Please select atleast one threshold`,
            color: `red`,
          };
          this.resetSnackbar();
          return;
        } else {
          //When Individual Required Fields are not Filled
          let allThresholdValidated = this.selectedSum.thresholds.filter(
            (thres) => {
              return thres.isError == true;
            }
          );
          if (allThresholdValidated.length > 0) {
            this.snackbar = {
              show: true,
              text: `Please Enter All the Fields in ${allThresholdValidated[0].name}`,
              color: `red`,
            };
            this.resetSnackbar();
            return;
          }
        }
      }

      let cleanedThresholds = this.selectedSum.thresholds.filter(
        (threshold) => {
          if (this.selectedSum.sum_type == "complex")
            return threshold.thrsh_from !== null && threshold.thrsh_to !== null;
          else if (this.selectedSum.sum_type == "complex_fixed")
            return threshold.thrsh_from !== null;
        }
      );
      this.selectedSum.thresholds = cleanedThresholds;
      return true;
    },
    thresholdValidate(threshold) {
      let thresholdIndex = this.selectedSum.thresholds.findIndex(
        (thres) => thres.value == threshold.value
      );
      let selectedThreshold = this.selectedSum.thresholds[thresholdIndex];

      if (selectedThreshold) {
        if (
          selectedThreshold.thrsh_from === null ||
          (this.selectedSum.sum_type === "complex" &&
            selectedThreshold.thrsh_to === null)
        ) {
          selectedThreshold.isError = true;
          return;
        } else if (
          selectedThreshold.rate === null &&
          selectedThreshold.amount === null
        ) {
          selectedThreshold.isError = true;
          return;
        } else {
          selectedThreshold.isError = false;
        }
      }
    },
    prepareThreshold() {
      this.selectedSum.thresholds = Array(5)
        .fill(null)
        .map((item, index) => {
          return {
            name: `Threshold ${index + 1}`,
            value: `threshold${index + 1}`,
            from: null,
            to: null,
            rate: null,
            amount: null,
            isError: false,
          };
        });
    },
    closeSum(id) {
      this.$emit(`closeSum`, id);
    },
    validateSum() {
      if (
        this.selectedSum.is_dependent_on_sum &&
        !this.selectedSum.dependent_on_sum_id
      ) {
        this.snackbar = {
          show: true,
          color: "red",
          text: "Please Enter Dependent Sum",
        };
        this.resetSnackbar();
      } else if (this.selectedSum.sum_type == null) {
        this.snackbar = {
          show: true,
          color: "red",
          text: "Please Select a Sum Type",
        };
        this.resetSnackbar();
      } else {
        let rateValidated = this.checkRatesValidation();
        if (rateValidated) this.updateSum();
      }
    },
    resetSnackbar() {
      setTimeout(() => {
        this.snackbar = {};
      }, 2000);
    },
    async updateSum() {
      let service = new BaseService(`sums`);
      let res = await service.update(this.selectedSum);
      if (res) {
        this.closeSum(this.selectedSum.section_id);
        this.$store.dispatch("common/setFlashMsg", "Saved successfully");
      }
    },
  },
};
</script>
