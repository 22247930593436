<template>
  <div>
    <div
      v-if="selectedProductSections && selectedProductSections.length > 0"
      class="sections"
    >
      <v-row>
        <v-col cols="5">
          <h3 class="ml-4">Product Sections</h3>
        </v-col>
        <v-col
          cols="7"
          class="text-right"
          v-if="
            JSON.stringify(selectedProductSections) !==
              JSON.stringify(filteredProductSections)
          "
        >
          <v-btn color="success" small outlined @click="saveSectionOrder"
            ><v-icon small class="mr-2">
              mdi-order-bool-ascending-variant</v-icon
            >
            Save Order</v-btn
          >
          <v-btn
            class="ml-2"
            color="grey"
            small
            outlined
            @click="resetSectionOrder"
            ><v-icon small class="mr-2"> mdi-restore</v-icon> Reset</v-btn
          >
        </v-col>
      </v-row>
      <div class="space-20"></div>

      <v-expansion-panels v-model="displayedSection" focusable popout>
        <Draggable style="width:100%" v-model="filteredProductSections">
          <v-expansion-panel
            v-for="(section, i) in filteredProductSections"
            :key="i"
          >
            <v-expansion-panel-header>
              <v-row>
                <v-col cols="1"><v-icon>mdi-drag </v-icon></v-col>
                <v-col cols="11" class="mt-2"> {{ section.name }}</v-col>
              </v-row>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <v-card v-if="loading" class="my-10">
                <v-skeleton-loader
                  v-bind="$attrs"
                  type="table-heading, list-item-two-line, image"
                ></v-skeleton-loader>
              </v-card>
              <template v-else>
                <div class="space-30"></div>
                <v-row>
                  <v-col cols="12" lg="3">
                    <v-switch
                      class="ml-4"
                      v-model="selectedSection.status"
                      inset
                      hide-details
                      label="Status"
                    ></v-switch>
                  </v-col>
                  <v-col cols="12" lg="9">
                    <v-switch
                      class="ml-4"
                      v-model="selectedSection.show_in_coverage_document"
                      inset
                      hide-details
                      label="Show in CoC"
                    ></v-switch>
                  </v-col>
                </v-row>
                <div class="space-30"></div>
                <v-row>
                  <v-col cols="12" lg="3">
                    <v-switch
                      class="ml-4"
                      v-model="selectedSection.is_mandatory"
                      inset
                      hide-details
                      label="Is Mandatory"
                    ></v-switch>
                  </v-col>
                  <!-- <v-col cols="12" lg="9">
                    <v-switch
                      class="ml-4"
                      v-model="selectedSection.taken"
                      inset
                      hide-details
                      label="Do you want include this Section?"
                    ></v-switch>
                  </v-col> -->
                </v-row>
                <div class="space-50"></div>
                <p>Section Description</p>

                <!-- <vue-editor 
                  v-model="selectedSection.descr" 
                  :disabled="!isRoleSuperAdmin" 
                  >
                </vue-editor> -->
                <v-textarea
                  v-model="selectedSection.descr"
                  v-html="selectedSection.descr"
                  outlined
                >
                </v-textarea>

                <div class="space-50"></div>

                <v-row>
                  <v-col cols="12" lg="6">
                    <p>Schedule Notes</p>
                    <v-textarea
                      outlined
                      class="mt-n2"
                      rows="2"
                      v-model="selectedSection.schedule_notes"
                      :readonly="!isRoleAdmin && !isRoleSuperAdmin"
                      label="Schedule Notes"
                    >
                    </v-textarea>
                  </v-col>
                  <v-col cols="12" lg="6">
                    <p>Notes</p>
                    <v-textarea
                      outlined
                      class="mt-n2"
                      rows="2"
                      v-model="selectedSection.notes"
                      :readonly="!isRoleAdmin && !isRoleSuperAdmin"
                      label="Notes"
                    >
                    </v-textarea>
                  </v-col>
                </v-row>

                <v-row class="mt-n5">
                  <v-col cols="12" lg="3">
                    <v-switch
                      class="ml-4"
                      v-model="selectedSection.is_dependent_on_section"
                      inset
                      hide-details
                      label="Dependent on Section"
                    ></v-switch>
                  </v-col>
                  <v-col cols="12" lg="9">
                    <div class="space-10"></div>
                    <v-select
                      :items="sectionsForDependency"
                      :disabled="!selectedSection.is_dependent_on_section"
                      label="Section Dependent"
                      @change="updateDependentId($event)"
                      clearable
                      v-model="selectedSection.dependent_on_section_id"
                      :readonly="!isRoleAdmin && !isRoleSuperAdmin"
                      item-text="name"
                      item-value="id"
                      outlined
                      dense
                    >
                    </v-select>
                  </v-col>
                </v-row>

                <v-row>
                  <v-col cols="12" lg="3">
                    <p>Excess</p>
                    <v-text-field
                      class="mt-n2"
                      label="Excess"
                      outlined
                      v-model.number="selectedSection.excess"
                      dense
                      type="number"
                    >
                    </v-text-field>
                  </v-col>
                  <v-col cols="12" lg="3">
                    <p>Additional Excess Name</p>
                    <v-text-field
                      class="mt-n2"
                      label="Additional Excel Name"
                      outlined
                      dense
                      v-model="selectedSection.additional_excess_name"
                    >
                    </v-text-field>
                  </v-col>
                  <v-col cols="12" lg="3">
                    <p>Additional excess</p>
                    <v-text-field
                      class="mt-n2"
                      label="Additional Excess"
                      outlined
                      dense
                      v-model="selectedSection.additional_excess"
                      type="number"
                    >
                    </v-text-field>
                  </v-col>
                  <v-col cols="12" lg="3">
                    <p>Winbeat Tag Name</p>
                    <v-text-field
                      class="mt-n2"
                      label="Winbeat Tag Name"
                      outlined
                      dense
                      v-model="selectedSection.wb_tag_name"
                    >
                    </v-text-field>
                  </v-col>
                </v-row>
                <div class="space-20"></div>
                <v-row
                  class="justify-end"
                  v-if="isRoleAdmin || isRoleSuperAdmin"
                >
                  <v-btn color="success" @click="validateSection">
                    <v-icon small left>mdi-check</v-icon>
                    Save
                  </v-btn>
                  <v-btn text @click="closeSection">
                    Cancel
                  </v-btn>
                </v-row>

                <div class="space-50"></div>

                <v-footer paddless class="py-3">
                  <v-row>
                    <v-col cols="5">
                      <strong text-center
                        ><v-icon small> mdi-plus-box</v-icon> SUMS</strong
                      >
                    </v-col>
                    <v-col
                      cols="7"
                      class="text-right"
                      v-if="
                        filteredSectionSums &&
                          filteredSectionSums.length > 0 &&
                          JSON.stringify(filteredSectionSums) !==
                            JSON.stringify(selectedSection.sums)
                      "
                    >
                      <v-btn
                        color="success"
                        small
                        outlined
                        @click="saveSumsOrder"
                        ><v-icon small class="mr-2">
                          mdi-order-bool-ascending-variant</v-icon
                        >
                        Save Order</v-btn
                      >
                      <v-btn
                        class="ml-2"
                        color="grey"
                        small
                        outlined
                        @click="resetSumOrder"
                        ><v-icon small class="mr-2"> mdi-restore</v-icon>
                        Reset</v-btn
                      >
                    </v-col>
                  </v-row>
                </v-footer>
                <template
                  v-if="filteredSectionSums && filteredSectionSums.length < 1"
                >
                  <div class="space-20"></div>
                  <p>
                    <v-icon color="error">mdi-alert-circle</v-icon> No Sums
                    found for this Product
                  </p>
                </template>
                <v-chip-group
                  v-model="selectedSumIndex"
                  active-class="primary--text"
                  column
                >
                  <Draggable v-model="filteredSectionSums">
                    <v-chip
                      v-for="sum in filteredSectionSums"
                      :key="sum.id"
                      @click="expandSelectedSum(sum)"
                      :color="
                        sum.sum_type === 'no_value' ? 'default' : '#99c470'
                      "
                      :text-color="
                        sum.sum_type === 'no_value' ? 'black' : 'white'
                      "
                      class="ma-2 px-10 py-2"
                    >
                      <v-icon left>mdi-drag</v-icon>
                      {{ sum.name }}
                    </v-chip>
                  </Draggable>
                </v-chip-group>
                <div class="space-20"></div>
                <v-dialog
                  hide-overlay
                  transition="dialog-bottom-transition"
                  fullscreen
                  persistent
                  v-model="sumDialog"
                  :retain-focus="false"
                >
                  <v-toolbar flat dark color="primary">
                    <v-btn icon dark @click="closeSum()">
                      <v-icon>mdi-close</v-icon>
                    </v-btn>
                    <v-toolbar-title>{{ selectedSum.name }}</v-toolbar-title>
                    <v-spacer></v-spacer>
                  </v-toolbar>
                  <v-card>
                    <ProductSumDetails
                      v-if="selectedSum.id"
                      :selectedSum="selectedSum"
                      :allSectionSums="selectedSection.sums"
                      @closeSum="closeSum"
                      :isRoleSuperAdmin="isRoleSuperAdmin"
                      :isRoleAdmin="isRoleAdmin"
                      @updateSum="updateSum"
                    />
                  </v-card>
                </v-dialog>
                <div class="space-30"></div>
              </template>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </Draggable>
      </v-expansion-panels>
    </div>

    <div v-else class="no-section text-center">
      <p>
        <v-icon color="error">mdi-alert-circle</v-icon> No Section found for
        this Product
      </p>
    </div>

    <Snackbar
      class="mb-10"
      v-if="snackbar.show"
      :show="snackbar.show"
      :text="snackbar.text"
      :color="snackbar.color"
    ></Snackbar>
  </div>
</template>
<script>
import ProductSumDetails from "./ProductSumsDetails";
import BaseService from "@/services/base.service.js";
import Snackbar from "@/components/common/Snackbar";
import Draggable from "vuedraggable";
import _ from "lodash";
// import { VueEditor } from 'vue2-editor';
import TemplateService from "@/services/template.service.js";

export default {
  name: "ProductSectionsSums",
  data() {
    return {
      selectedSum: {},
      selectedSection: {},
      displayedSection: null,
      selectedSumIndex: null,
      sumDialog: false,
      snackbar: {},
      loading: false,
      filteredProductSections: [],
      filteredSectionSums: [],
    };
  },
  props: {
    selectedProductSections: Array,
    templateId: Number,
    productId: Number,
  },
  components: {
    Snackbar,
    ProductSumDetails,
    Draggable,
    // VueEditor
  },
  mounted() {
    this.createFilteredlist(`sections`);
  },
  methods: {
    createFilteredlist(type) {
      if (type == "sections") {
        this.filteredProductSections = _.cloneDeep(
          this.selectedProductSections
        );
      } else {
        // this.selectedSection.sums = this.selectedSection.sums.filter(
        //   (item) => item.sum_type !== "no_value"
        // );
        this.filteredSectionSums = _.cloneDeep(this.selectedSection.sums);
      }
    },
    expandSelectedSum(sum) {
      this.selectedSum = sum;
      this.sumDialog = !this.sumDialog;
    },
    async saveSectionOrder() {
      let params = {
        template_id: this.templateId,
        product_id: this.productId,
      };

      let newArray = [];
      this.filteredProductSections.map((item) => {
        if (item.id) {
          newArray.push({ id: item.id });
        }
      });

      let res = await TemplateService.save_order("sections", params, newArray);
      if (res) {
        this.filteredProductSections = res.data;
        this.$emit(`sectionOrderSaved`, res.data);
        this.$store.dispatch(
          "common/setFlashMsg",
          "Section Order Saved Successfully"
        );
      }
    },
    async saveSumsOrder() {
      let params = {
        template_id: this.templateId,
        product_id: this.productId,
        section_id: this.selectedSection.id,
      };

      let newArray = [];
      this.filteredSectionSums.map((item) => {
        if (item.id) {
          newArray.push({ id: item.id });
        }
      });

      let res = await TemplateService.save_order("sums", params, newArray);
      if (res) {
        this.filteredSectionSums = res.data;
        this.selectedSection.sums = res.data;
        this.$store.dispatch(
          "common/setFlashMsg",
          "Sum Order Saved Successfully"
        );
      }
    },
    resetSectionOrder() {
      this.createFilteredlist(`sections`);
    },
    resetSumOrder() {
      this.createFilteredlist(`sums`);
    },
    validateSection() {
      if (this.selectedSection.is_dependent_on_section) {
        if (!this.selectedSection.dependent_on_section_id) {
          this.snackbar = {
            show: true,
            color: "red",
            text: "Please Enter Dependent Section",
          };
          this.resetSnackbar();
        } else {
          this.updateSection();
        }
      } else {
        this.updateSection();
      }
    },
    updateDependentId(id) {
      console.log(id);
    },
    updateSum() {
      console.log(`sum updated`);
    },
    async updateSection() {
      delete this.selectedSection.sums;
      let service = new BaseService(`sections`);
      let res = await service.update(this.selectedSection);
      if (res) {
        this.$store.dispatch("common/setFlashMsg", "Saved successfully");
        this.closeSection();
      }
    },
    resetSnackbar() {
      setTimeout(() => {
        this.snackbar = {};
      }, 2000);
    },
    closeSection() {
      this.displayedSection = null;
    },
    async closeSum(id) {
      this.selectedSum = {};
      this.sumDialog = false;
      this.selectedSumIndex = null;

      if (id) {
        await this.getSectionInfo(id);
        this.resetSumOrder();
      }
    },
    async getSectionInfo(section_id) {
      this.loading = true;
      let service = new BaseService("sections");
      let res = await service.get_one(section_id);
      if (res) {
        this.selectedSection = res.data;
        this.loading = false;
      }
    },
  },
  computed: {
    userRole() {
      return this.$store.getters[`auth/userRole`];
    },
    isRoleSuperAdmin() {
      if (this.userRole == "SUPER_ADMIN") return true;
      else return false;
    },
    isRoleAdmin() {
      if (this.userRole == "ADMIN") return true;
      else return false;
    },
    sectionsForDependency() {
      if (this.selectedSection) {
        return this.selectedProductSections.filter(
          (item) => item.id != this.selectedSection.id
        );
      } else {
        return this.selectedProductSections;
      }
    },
  },
  watch: {
    selectedSumIndex(val) {
      if (val == null || val == undefined) {
        this.selectedSum = {};
      }
    },
    async displayedSection(val) {
      if (val !== null || val !== undefined) {
        let section_id =
          this.selectedProductSections[val] &&
          this.selectedProductSections[val].id;
        if (section_id) {
          await this.getSectionInfo(section_id);
          this.createFilteredlist(`sums`);
        }
      }
    },
  },
};
</script>
