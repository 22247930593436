<template>
  <div>
    <div class="commission-details">
      <h3>Commission & Fee Structure</h3>
      <v-divider class="mt-3 mb-5"></v-divider>

      <v-form v-model="isProductValid" ref="product-details">
        <v-simple-table>
          <template>
            <thead>
              <tr>
                <th class="text-left">
                  Edit Items
                </th>
                <th class="text-left">
                  Add/Remove
                </th>
                <th class="text-left" style="width:300px">
                  Details Assigned
                </th>

                <th class="text-left" style="width:120px">
                  Commission <br />
                  (% New)
                </th>
                <th class="text-left" style="width:120px">
                  Commission <br />
                  (% Ren)
                </th>
                <th class="text-left" style="width:120px"></th>
                <th class="text-left" style="width:120px">
                  Fee <br />
                  (<v-icon small>mdi-currency-usd</v-icon>New)
                </th>
                <th class="text-left" style="width:120px">
                  Fee <br />
                  (<v-icon small>mdi-currency-usd</v-icon>Ren)
                </th>
                <th class="text-left" style="width:120px">
                  Fee <br />
                  (<v-icon small>mdi-currency-usd</v-icon>End)
                </th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="item in selectedProduct.fees"
                :key="item.party_type_name"
              >
                <td>{{ item.party_type_name | capitalize }}</td>

                <td>
                  <v-switch v-model="item.status"> </v-switch>
                </td>
                <template v-if="item.party_type == 1">
                  <td>
                    <v-autocomplete
                      outlined
                      dense
                      clearable
                      @focus="getAllAgent('brokers', 'insurerList', 1)"
                      @update:search-input="prepareAgent($event, 'insurer', 1)"
                      :search-input.sync="selectedAgent.insurer"
                      class="mt-5"
                      :disabled="!item.status"
                      item-text="name"
                      item-value="id"
                      :items="insurerList"
                      :rules="checkMandatory(item, `party_id`)"
                      v-model="item.party_id"
                      label="Select Insurer"
                      autocomplete="nope"
                    >
                    </v-autocomplete>
                  </td>
                </template>
                <template v-else-if="item.party_type == 2">
                  <td>
                    <v-autocomplete
                      outlined
                      dense
                      clearable
                      @focus="getAllAgent('brokers', 'brokerList', 2)"
                      @update:search-input="prepareAgent($event, 'broker', 2)"
                      :search-input.sync="selectedAgent.broker"
                      item-text="name"
                      item-value="id"
                      :disabled="!item.status"
                      class="mt-5"
                      :items="brokerList"
                      :rules="checkMandatory(item, `party_id`)"
                      v-model="item.party_id"
                      label="Select Broker"
                      autocomplete="nope"
                    >
                    </v-autocomplete>
                  </td>
                </template>
                <template v-if="item.party_type == 3">
                  <td>
                    <v-autocomplete
                      outlined
                      dense
                      clearable
                      @focus="getAllAgent('franchises', 'franchiseList')"
                      @update:search-input="prepareAgent($event, 'franchise')"
                      :search-input.sync="selectedAgent.franchise"
                      item-text="name"
                      :disabled="!item.status"
                      item-value="id"
                      class="mt-5"
                      :rules="checkMandatory(item, `party_id`)"
                      :items="franchiseList"
                      v-model="item.party_id"
                      label="Select Referer"
                      autocomplete="nope"
                    >
                    </v-autocomplete>
                  </td>
                </template>
                <td>
                  <v-text-field
                    class="mt-3"
                    type="number"
                    :disabled="!item.status"
                    outlined
                    @input="updateTotal(`party_new_commission`)"
                    :rules="checkMandatory(item, `party_new_commission`)"
                    v-model.number="item.party_new_commission"
                    dense
                    label="% New"
                  >
                  </v-text-field>
                </td>
                <td>
                  <v-text-field
                    class="mt-3"
                    outlined
                    :disabled="!item.status"
                    @input="updateTotal(`party_renew_commission`)"
                    :rules="checkMandatory(item, `party_renew_commission`)"
                    type="number"
                    dense
                    v-model.number="item.party_renew_commission"
                    label="% Ren"
                  >
                  </v-text-field>
                </td>
                <td></td>
                <td>
                  <v-text-field
                    class="mt-3"
                    outlined
                    :disabled="!item.status"
                    @input="updateTotal(`party_new_fees`)"
                    :rules="checkMandatory(item, `party_new_fees`)"
                    dense
                    type="number"
                    v-model.number="item.party_new_fees"
                    label="$ New"
                    prefix="$"
                  >
                  </v-text-field>
                </td>
                <td>
                  <v-text-field
                    class="mt-3"
                    :disabled="!item.status"
                    v-model.number="item.party_renew_fees"
                    @change="updateTotal(`party_renew_fees`)"
                    :rules="checkMandatory(item, `party_renew_fees`)"
                    outlined
                    prefix="$"
                    dense
                    type="number"
                    label="$ Ren"
                  >
                  </v-text-field>
                </td>
                <td>
                  <v-text-field
                    class="mt-3"
                    v-model.number="item.party_end_fees"
                    :disabled="!item.status"
                    :rules="checkMandatory(item, `party_end_fees`)"
                    @change="updateTotal(`party_end_fees`)"
                    outlined
                    dense
                    type="number"
                    label="$ End"
                    prefix="$"
                  >
                  </v-text-field>
                </td>
              </tr>
              <tr>
                <td></td>
                <td></td>
                <td>
                  <h3 class="text-right mt-n3">Total Commision</h3>
                </td>
                <td>
                  <v-text-field
                    class="mt-3"
                    v-model.number="feeTotals.total_party_new_commission"
                    outlined
                    readonly
                    dense
                    type="number"
                    label="% New"
                  >
                  </v-text-field>
                </td>
                <td>
                  <v-text-field
                    class="mt-3"
                    v-model.number="feeTotals.total_party_renew_commission"
                    outlined
                    readonly
                    dense
                    type="number"
                    label="% New"
                  >
                  </v-text-field>
                </td>
                <h4 class="text-right mt-5">Total Fees</h4>
                <td>
                  <v-text-field
                    class="mt-3"
                    :value="feeTotals.total_party_new_fees"
                    outlined
                    readonly
                    dense
                    type="number"
                    label="$ New"
                    prefix="$"
                  >
                  </v-text-field>
                </td>
                <td>
                  <v-text-field
                    class="mt-3"
                    :value="feeTotals.total_party_renew_fees"
                    outlined
                    readonly
                    dense
                    type="number"
                    label="$ Ren"
                    prefix="$"
                  >
                  </v-text-field>
                </td>
                <td>
                  <v-text-field
                    class="mt-3"
                    :value="feeTotals.total_party_end_fees"
                    outlined
                    readonly
                    dense
                    type="number"
                    label="$ End"
                    prefix="$"
                  >
                  </v-text-field>
                </td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
      </v-form>
    </div>

    <div class="product-details ">
      <h3>Product Details</h3>
      <v-divider class="mt-3 mb-5"></v-divider>

      <v-row class="my-0">
        <v-col cols="12" lg="3">
          <p>Excess</p>
          <v-text-field
            v-model.number="selectedProduct.info.excess"
            outlined
            dense
            type="number"
            label="$ Excess"
          >
          </v-text-field>
        </v-col>
        <v-col cols="12" lg="6">
          <p>Formal Policy (Ref No)</p>
          <v-text-field
            outlined
            v-model="selectedProduct.info.policy_ref_number"
            dense
            label="Enter Policy Ref no "
          ></v-text-field>
        </v-col>
        <v-col cols="12" lg="3">
          <p>Winbeat Tag Name</p>
          <v-text-field
            v-model.number="selectedProduct.info.wb_tag_name"
            outlined
            dense
            label="Winbeat Tag Name"
          >
          </v-text-field>
        </v-col>
      </v-row>

      <v-row class="mt-n3">
        <v-col cols="3">
          <p>Unique Market Reference</p>
        </v-col>
        <v-col cols="3">
          <v-text-field
            class="mx-5"
            v-model="selectedProduct.info.umr_current"
            outlined
            dense
            label="Current (YOA)"
          >
          </v-text-field>
        </v-col>
        <v-col cols="3">
          <v-text-field
            class="mx-5"
            v-model="selectedProduct.info.umr_future"
            outlined
            dense
            label="Future (YOA)"
          >
          </v-text-field>
        </v-col>
      </v-row>
    </div>
    <v-row class="justify-end my-5">
      <v-btn color="success" @click="saveProduct">
        <v-icon small left>mdi-check</v-icon>
        Save
      </v-btn>

      <v-btn text @click="closeProductDetails">
        Cancel
      </v-btn>
    </v-row>
    <div class="space-20"></div>
  </div>
</template>
<script>
import BrokerService from "@/services/broker.service.js";
import BaseService from "@/services/base.service.js";
import FranchiseService from "@/services/franchise.service.js";
import { debounce } from "@/helpers/basic_helper";

export default {
  name: "TemplateProductDetails",
  data() {
    return {
      insurerList: [],
      brokerList: [],
      franchiseList: [],
      isProductValid: false,
      feeTotals: {},
    };
  },
  props: {
    selectedProduct: Object,
    isProductExisting: Number,
    productsList: Array,
    selectedAgent: Object,
  },
  watch: {
    "selectedProduct.product_id": {
      handler(val) {
        if (val != null) {
          this.updateTotalsManually();
        }
      },
      immediate: true,
    },
  },
  filters: {
    capitalize(val) {
      let lower = val.toLowerCase();
      return lower.charAt(0).toUpperCase() + lower.slice(1);
    },
  },
  mounted() {
    this.selectedProduct.fees.forEach((item) => {
      if (item.party_type_name === "INSURER" && item.party_id) {
        this.insurerList = [{ id: item.party_id, name: item.party_name }];
      } else if (item.party_type_name === "BROKER" && item.party_id) {
        this.brokerList = [{ id: item.party_id, name: item.party_name }];
      } else if (item.party_type_name === "REFERER" && item.party_id) {
        this.franchiseList = [{ id: item.party_id, name: item.party_name }];
      }
    });
  },
  methods: {
    updateTotalsManually() {
      let props = [
        `party_new_commission`,
        `party_renew_commission`,
        `party_new_fees`,
        `party_renew_fees`,
        `party_end_fees`,
      ];
      props.map((p) => this.updateTotal(p));
    },
    prepareAgent(event, agent, type_id) {
      let searchTerm = event;
      if (searchTerm) {
        let params;
        switch (agent) {
          case "insurer":
            params = { name: searchTerm, type_id: type_id };
            this.getAgent(agent, params, "insurerList");
            break;
          case "broker":
            params = { name: searchTerm, type_id: type_id };
            this.getAgent(agent, params, "brokerList");
            break;
          case "franchise":
            params = { name: searchTerm };
            this.getAgent(agent, params, "franchiseList");
            break;
        }
      }
    },
    async getAllAgent(type, arr, type_id) {
      let agentService = new BaseService(type);
      let service;

      service = agentService.get_all({ type: type_id });

      try {
        let res = await service;
        if (res) {
          this[arr] = res.data.results;
        }
      } catch (error) {
        console.log(error.response ? error.response : error);
      }
    },
    getAgent: debounce(async function(agent, params, list) {
      let service;

      if (agent == "insurer" || agent == "broker") {
        service = BrokerService.get_name_suggestions(
          params.type_id,
          params.name
        );
      } else {
        service = FranchiseService.get_name_suggestions(params.name);
      }

      try {
        let res = await service;
        if (res) this.$set(this, list, res.data.results);
      } catch (error) {
        console.log(error.response ? error.response : error);
      }
    }, 400),
    closeProductDetails() {
      this.$emit(`closeProductDetails`);
    },
    updateTotal(type) {
      if (this.selectedProduct.fees && this.selectedProduct.fees.length > 0) {
        let allTypes = this.selectedProduct.fees
          .filter((item) => item[type] && item[type])
          .map((item) => parseFloat(item[type]));

        let total =
          allTypes.length && allTypes.reduce((prev, cur) => prev + cur);
        let totalType = `total_${type}`;
        this.$set(this.feeTotals, totalType, total.toFixed(2));
      }
    },
    prepareJSONForSaving() {
      let fees = this.selectedProduct.fees;
      fees.forEach((agent) => {
        Object.keys(agent).forEach((key) => {
          if (agent[key] == "") agent[key] = 0;
        });
      });
    },
    saveProduct() {
      this.$refs[`product-details`].validate();
      if (this.isProductValid) {
        this.prepareJSONForSaving();
        if (
          this.isProductExisting !== null ||
          this.isProductExisting === undefined
        ) {
          this.$emit(`saveProduct`, "update");
        } else {
          this.$emit(`saveProduct`, `create`);
        }
      }
    },
    updateAssignedProduct() {
      this.$refs[`product-details`].validate();
      if (this.isProductValid) this.$emit(`updateAssignedProduct`);
    },
    checkMandatory(item, type) {
      if (item.status) {
        if (type == "party_id" && !item[type])
          return [`Please Add ${item.party_type_name.toLowerCase()}`];
      }

      if (type == "party_new_commission" || type == "party_renew_commission") {
        if (item[type] > 100) return [`Must be less than 100`];
      }

      if (item[type] < 0) {
        return [`Cannot be Negative`];
      }
    },
  },
};
</script>
