<template>
  <div v-if="template">
    <v-form ref="form" v-model="valid" lazy-validation>
      <v-container fluid>
        <v-row no-gutters>
          <v-col>
            <v-autocomplete
              v-model="template.broker_id"
              :loading="broker_loading"
              @focus="getAllBrokers"
              :items="broker_items"
              :search-input.sync="broker_search"
              item-text="name"
              item-value="id"
              flat
              label="broker"
              autocomplete="nope"
            ></v-autocomplete>
          </v-col>
        </v-row>
        <v-row no-gutters>
          <v-btn
            class="my-5"
            :loading="loading"
            :disabled="loading"
            color="success"
            @click="save"
          >
           <v-icon small left >mdi-check</v-icon> 
            Save
          </v-btn>
        </v-row>
      </v-container>
    </v-form>
  </div>
</template>

<script>
import { debounce } from "@/helpers/basic_helper";
import templateService from "@/services/template.service.js";
import brokerService from "@/services/broker.service.js";
import BaseService from "@/services/base.service.js";

export default {
  name: "template-broker-edit",

  props: ["template"],

  data: () => ({
    loading: false,
    valid: true,

    // broker
    broker_loading: false,
    broker_items: [],
    broker_search: null,
    // select: null,
  }),

  methods: {
    validate() {
      let result = this.$refs.form.validate();
      if (result) {
        this.save();
      }
    },
    reset() {
      this.$refs.form.reset();
    },
    async save() {
      this.loading = true;
      let res = null;
      let obj = { id: this.template.id, broker_id: this.template.broker_id };
      res = await templateService.patch(obj);
      this.loading = false;
      if (res.status == 401) {
        this.handleLogout();
      } else if (res.status == 200 || res.status == 201) {
        this.$emit("edit_save", { msg: "Saved successfully", data: res.data });
      } else {
        this.$emit("edit_error", { msg: "An error occurred, could not save" });
      }
    },

    async get_broker_items(v) {
      this.broker_loading = true;
      let res = null;
      try {
        let type_id = 2;
        res = await brokerService.get_name_suggestions(type_id, v);
      } catch (e) {
        // nothing here
      } finally {
        this.broker_loading = false;
      }
      return res.data.results;
    },

    get_default_broker_options() {
      return this.template.broker && this.template.broker.id
        ? [
            { id: null, name: "None" },
            { id: this.template.broker.id, name: this.template.broker.name },
          ]
        : [];
    },

    handleLogout() {
      this.$store.dispatch("auth/logout");
    },

    async getAllBrokers() {
      let service = new BaseService("brokers");
      this.broker_loading = true;
      let res = null;
      try {
        res = await service.get_all();
      } catch (e) {
        // nothing here
      } finally {
        this.broker_loading = false;
      }
      this.broker_items = res.data.results;
    },
  },

  watch: {
    template(/* val */) {
      this.broker_items = this.broker_items = this.get_default_broker_options();
    },

    broker_search: debounce(async function(val) {
      let broker_name = this.template.broker ? this.template.broker.name : "";
      if (val && val !== broker_name) {
        try {
          this.broker_items = [
            { id: null, name: "None" },
            ...(await this.get_broker_items(val)),
          ];
        } catch (e) {
          // console.log(e);
        }
      }
    }, 500),
  },

  mounted() {
    if (this.template) {
      this.broker_items = this.get_default_broker_options();
    }
  },
};
</script>

<style lang="scss" scoped></style>
