<template>
  <div>
    <v-row>
      <v-col cols="12" lg="4" md="3" sm="12">
        <h3 class="ml-4">Endorsements</h3>
      </v-col>
      <v-col cols="12" lg="6" md="5" sm="12" class="text-right">
        <template
          v-if="
            JSON.stringify(productEndorsements) !==
              JSON.stringify(filteredProductEndorsements)
          "
        >
          <v-btn color="success" small outlined @click="saveOrder"
            ><v-icon small class="mr-2">
              mdi-order-bool-ascending-variant</v-icon
            >
            Save Order</v-btn
          >
          <v-btn class="ml-2" color="grey" small outlined @click="resetOrder"
            ><v-icon small class="mr-2"> mdi-restore</v-icon> Reset</v-btn
          >
        </template>
      </v-col>
      <v-col cols="12" lg="2" md="3" sm="12">
        <v-btn color="success" small @click="openEndorsementModal">
          <v-icon small left>mdi-plus</v-icon>
          Endorsement
        </v-btn>
      </v-col>
    </v-row>
    <div class="space-20"></div>
    <div v-if="filteredProductEndorsements">
      <v-list>
        <Draggable v-model="filteredProductEndorsements">
          <v-list-item
            v-for="endorse in filteredProductEndorsements"
            :key="endorse.id"
          >
            <v-row>
              <v-col cols="1"><v-icon>mdi-drag </v-icon></v-col>
              <v-col cols="10">
                <p>{{ endorse.name }}</p></v-col
              >
              <v-col cols="1">
                <v-icon class="mr-2" @click="editEndorsement(endorse)"
                  >mdi-pencil</v-icon
                >
                <v-icon @click="removeEndorsement(endorse)">mdi-delete</v-icon>
              </v-col>
            </v-row>
          </v-list-item>
        </Draggable>
      </v-list>
    </div>

    <v-dialog
      hide-overlay
      transition="dialog-bottom-transition"
      fullscreen
      persistent
      v-model="addEndorsementModal"
      :retain-focus="false"
    >
      <v-toolbar flat dark color="primary">
        <v-btn icon dark @click="closeEndorsementModal">
          <v-icon>mdi-close</v-icon>
        </v-btn>
        <v-toolbar-title>Add New Endorsement</v-toolbar-title>
        <v-spacer></v-spacer>
      </v-toolbar>
      <v-card>
        <AddEditEndorsement
          @closeEndorsementModal="addEndorsementModal = false"
          :endorsement="endorsement"
          :endorsementSections="endorsementSections"
          @saveEndorsement="saveEndorsement"
          :templateId="selectedProduct.template_id"
          :productId="selectedProduct.product_id"
          ref="addEditEndorsement"
        />
      </v-card>
    </v-dialog>
    <BooleanDialog
      :dialog="deleteDialog"
      :item_to_delete="endorsementToDelete"
      @confirmed="deleteEndorsement"
      @cancelled="deleteDialog = false"
    />
  </div>
</template>

<script>
import AddEditEndorsement from './AddEditEndorsement';
import Draggable from 'vuedraggable';
import BooleanDialog from '@/components/common/BooleanDialog.vue';
import BaseService from '@/services/base.service.js';
import TemplateService from '@/services/template.service.js';

export default {
  name: 'ProductEndorsements',
  props: {
    selectedProduct: Object,
  },
  components: {
    AddEditEndorsement,
    Draggable,
    BooleanDialog,
  },
  data() {
    return {
      addEndorsementModal: false,
      CRUDState: '',
      endorsementToDelete: {},
      deleteDialog: false,
      endorsement: {
        status: true,
      },
      productEndorsements: [],
      filteredProductEndorsements: [],
      endorsementSections: [],
    };
  },
  mounted() {
    this.getEndorsements();
    this.getEndorsementSections();
  },
  methods: {
    closeEndorsementModal() {
      this.addEndorsementModal = false;
    },
    openEndorsementModal() {
      this.CRUDState = 'create';
      this.addEndorsementModal = true;
      this.endorsement = { status: true };
      if (this.$refs[`addEditEndorsement`])
        this.$refs[`addEditEndorsement`].$refs.form.resetValidation();
    },
    removeEndorsement(endorsement) {
      this.deleteDialog = true;
      this.endorsementToDelete = endorsement;
    },
    editEndorsement(endorsement) {
      this.CRUDState = 'edit';
      this.addEndorsementModal = true;
      this.endorsement = endorsement;
    },
    async deleteEndorsement() {
      let service = new BaseService(`endorsements`);
      try {
        await service.delete(this.endorsementToDelete.id);
        this.deleteDialog = false;
        this.getEndorsements();
      } catch (error) {
        console.log(`error`, error);
      }
    },
    async getEndorsementSections() {
      let service = new BaseService('endorsements/sections');
      let params = {
        section: 1,
      };
      try {
        let res = await service.get_all(params);
        this.endorsementSections = res.data.data;
      } catch (error) {
        console.log(`error`, error);
      }
    },
    async getEndorsements() {
      let service = new BaseService('endorsements');
      let params = {
        template_id: this.selectedProduct.template_id,
        product_id: this.selectedProduct.product_id,
      };
      try {
        let res = await service.get_all(params);
        this.productEndorsements = res.data.results.map((item) => {
          if (item.position_section) {
            return {
              ...item,
              position_section: parseInt(item.position_section),
            };
          } else {
            return item;
          }
        });
        this.filteredProductEndorsements = [...this.productEndorsements];
      } catch (error) {
        console.log(`error`, error);
      }
    },
    async saveEndorsement() {
      this.$set(
        this.endorsement,
        'template_id',
        this.selectedProduct.template_id
      );
      this.$set(
        this.endorsement,
        'product_id',
        this.selectedProduct.product_id
      );
      let service = new BaseService('endorsements');

      if (this.CRUDState == 'create') {
        await service.create(this.endorsement);
        this.getEndorsements();
      } else {
        await service.update(this.endorsement);
      }
      this.$store.dispatch('common/setFlashMsg', 'Saved successfully');
      this.addEndorsementModal = false;
    },
    async saveOrder() {
      let params = {
        template_id: this.selectedProduct.template_id,
        product_id: this.selectedProduct.product_id,
      };

      let newArray = [];
      this.filteredProductEndorsements.map((item) => {
        if (item.id) {
          newArray.push({ id: item.id });
        }
      });

      try {
        let res = await TemplateService.save_order(
          'endorsements',
          params,
          newArray
        );
        if (res && res.status == 200) {
          this.filteredProductEndorsements = res.data.data;
          this.$store.dispatch(
            'common/setFlashMsg',
            'Endorsement Order Saved Successfully'
          );
        }
      } catch (error) {
        console.log(
          '🚀 ~ file: ProductEndorsements.vue ~ line 187 ~ saveOrder ~ error',
          error
        );
      }
    },
    resetOrder() {
      this.filteredProductEndorsements = this.productEndorsements;
    },
  },
};
</script>
